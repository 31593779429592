import { SelectedOptionCategory, SelectedProductCategory } from '../../store/GeneralState/GeneralState.reducer'

export const checkForSelectedOptionCategory = (
    selectedProductCategory: SelectedProductCategory,
    productTypeID: string,
    optionCategoryID: string,
): SelectedOptionCategory | undefined => {
    let optionCategoryToReturn: SelectedOptionCategory | undefined

    if (selectedProductCategory.selectedProduct) {
        const selectedProductType = selectedProductCategory.selectedProduct.productTypes.find(
            (spt) => spt.id === productTypeID,
        )
        if (selectedProductType) {
            optionCategoryToReturn = selectedProductType.optionCategories.find((sop) => sop.id === optionCategoryID)
        }
    }

    return optionCategoryToReturn
}
