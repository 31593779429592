import gql from 'graphql-tag'

export default gql`
    mutation SaveOrderProgress(
        $email: String!
        $data: String!
        $key: String!
        $salutation: String!
        $vzfID: String!
        $isSalesPartner: Boolean!
    ) {
        saveOrderProgress(
            email: $email
            data: $data
            key: $key
            salutation: $salutation
            vzfID: $vzfID
            isSalesPartner: $isSalesPartner
        ) {
            status
        }
    }
`
