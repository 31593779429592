import { Grid, TextField, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { AddressData, PostCodeDetail, PostCodeObject } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'
import usePostCodeAddrState from './usePostCodeAddrState'

export interface PostCodeAddressProps {
    data: AddressData
    handleAddressSelect: (payload: PostCodeDetail) => void
}

const PostCodeAddress = (props: PostCodeAddressProps): JSX.Element => {
    const { data, handleAddressSelect } = props
    const { t } = useTranslation()
    const { onChangeTerm, onClose, onOpen, onSelectOption, open, options, term } =
        usePostCodeAddrState(handleAddressSelect)

    const getOptionLabel = (option: PostCodeObject): string =>
        option.label + (option.description === '' ? '' : ` ${option.description}`)

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return <TextField {...params} variant="outlined" {...TestIDs.GET_AUTOCOMPLETE('SEARCH_FIELD')} />
    }

    // NOTE: is required === filterOptions&& getOptionsSelected !
    // otherwise it will filter new items out from the REQUEST

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item xs={12}>
                <Typography>{t('enterAddressPostCode')}</Typography>
            </Grid>
            <Grid item container direction="row" alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <SearchIcon />
                </Grid>
                <Grid item xs={11}>
                    <Autocomplete
                        id={'autocompleteTextField-1'}
                        options={options}
                        style={{ width: '100%' }}
                        filterOptions={(x) => x}
                        getOptionLabel={getOptionLabel}
                        getOptionSelected={(x) => x === x}
                        onChange={onSelectOption}
                        renderInput={renderInput}
                        freeSolo
                        disableClearable
                        disablePortal
                        open={open}
                        onOpen={onOpen}
                        onClose={onClose}
                        inputValue={term}
                        onInputChange={onChangeTerm}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="row" alignItems={'center'} justifyContent={'center'} spacing={1}>
                <Grid item xs={12} md={10}>
                    <TextField
                        label={t('street')}
                        variant={'outlined'}
                        fullWidth={true}
                        disabled={true}
                        value={data.street}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        label={t('number')}
                        variant={'outlined'}
                        fullWidth={true}
                        disabled={true}
                        value={data.houseNumber}
                    />
                </Grid>
            </Grid>

            <Grid item container direction="row" alignItems={'center'} justifyContent={'center'} spacing={1}>
                <Grid item xs={12} md={2}>
                    <TextField
                        label={t('zip')}
                        variant={'outlined'}
                        fullWidth={true}
                        disabled={true}
                        value={data.zipcode}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <TextField
                        label={t('city')}
                        variant={'outlined'}
                        fullWidth={true}
                        disabled={true}
                        value={data.city}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PostCodeAddress
