// import ProductCategoriesQuery from 'graphql/queries/ProductCategories'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { checkIfErrorCategoryExists } from 'utils/testable/checkIfErrorCategoryExists'
import { ProductCategory } from '../../graphql/types'
import GeneralStateActions, { GeneralStateAction } from '../../store/GeneralState/GeneralState.actions'
import { Page, SelectedProductCategory, ViewType } from '../../store/GeneralState/GeneralState.reducer'
import { AppState } from '../../store/store'

interface ProductCategoriesSelectionReducerReturn {
    currentView: ViewType
    availableProductCategories: ProductCategory[]
    selectedProductCategories: SelectedProductCategory[]
    pagesList: Page[]
    currentPage: number
    setSelectedProductCategories: (payload: { id: string; B2B: boolean }) => void
}

export const useProductCategoriesSelectionReducer: () => ProductCategoriesSelectionReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { availableProductCategories, currentView, errors, selectedProductCategories, currentPage, pagesList } =
        useSelector((appState: AppState) => {
            return {
                availableProductCategories: appState.generalState.availableProductCategories,
                currentView: appState.generalState.currentView,
                errors: appState.generalState.errors,
                selectedProductCategories: appState.generalState.selectedProductCategories,
                pagesList: appState.generalState.pagesList,
                currentPage: appState.generalState.currentPage,
            }
        })

    const clearLoadOrderErrors = useCallback(() => {
        dispatch({ type: GeneralStateAction.CLEAR_ERROR_CATEGORY, payload: 'loadOrder' })
    }, [dispatch])

    const setSelectedProductCategories = useCallback(
        (payload: { id: string; B2B: boolean }) => {
            if (checkIfErrorCategoryExists(errors, 'loadOrder')) {
                clearLoadOrderErrors()
            }
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT_CATEGORY, payload })
        },
        [dispatch, errors, clearLoadOrderErrors],
    )

    return {
        currentView,
        availableProductCategories,
        selectedProductCategories,
        setSelectedProductCategories,
        pagesList,
        currentPage,
    }
}
