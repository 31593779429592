import { Box } from '@material-ui/core'
import OptionItem, { OptionItemContent } from 'components/OptionItem/OptionItem'
import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { useProductTypeSelectionReducer } from './useProductTypeSelectionReducer'

const ProductTypeSelection = (): JSX.Element => {
    const { t } = useTranslation()
    const { B2B } = useURLParams()
    const {
        // availableProductCategories,
        currentView,
        selectedProductCategoryFromData,
        productType,
        setSelectedProductType,
        productTypesToShow,
        selectedProductCategory,
        // currentPage,
        // pagesList,
        disabledSubmit,
    } = useProductTypeSelectionReducer()

    const [userInfo] = useUserInfo()

    // const submitButtonText = getSubmitButtonTextForProductBasedViews(pagesList, currentPage, availableProductCategories)

    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.header.' + productType)}
            subHeader={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.subheader.' + productType)}
            submitButtonText={t(currentView + 'Strings.goTo')}
            tooltipText={
                t(
                    currentView +
                        'Strings.' +
                        'infoText' +
                        (selectedProductCategoryFromData ? '.' + selectedProductCategoryFromData.identifier : ''),
                ) ===
                currentView +
                    'Strings.' +
                    'infoText' +
                    (selectedProductCategoryFromData ? '.' + selectedProductCategoryFromData.identifier : '')
                    ? ''
                    : t(
                          currentView +
                              'Strings.' +
                              'infoText' +
                              (selectedProductCategoryFromData ? '.' + selectedProductCategoryFromData.identifier : ''),
                      )
            }
            viewType={currentView}
        >
            <Box width={1} marginTop={2}>
                {productTypesToShow.map(
                    (option: OptionItemContent): JSX.Element => (
                        <Box mb={'9px'} key={option.id + option.title}>
                            <OptionItem
                                content={option}
                                selected={
                                    selectedProductCategory?.selectedProduct?.productTypes.findIndex(
                                        (value) => value.id === option.id,
                                    ) !== -1
                                }
                                type={OptionType.CHECKBOX}
                                onSelect={(id) => {
                                    setSelectedProductType({
                                        productCategoryId: selectedProductCategory?.id ?? '',
                                        productId: selectedProductCategory?.selectedProduct?.id ?? '',
                                        productTypeId: id,
                                        B2B,
                                    })
                                }}
                            />
                        </Box>
                    ),
                )}
            </Box>
        </ViewWrapper>
    )
}

export default ProductTypeSelection
