// import ProductCategoriesQuery from 'graphql/queries/ProductCategories'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'

interface ProductSelectionReducerReturn {
    currentView: ViewType
    voucherCode: string
    redeemManualVoucher: boolean
    inLineErrors: string[]
    onVoucherCodeChange: (code: string) => void
    setVoucherCode: (payload: string) => void
    setRedeemManualVoucher: (payload: boolean) => void
}

export const useManualVoucherRedemptionReducer: () => ProductSelectionReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { currentView, inLineErrors, voucherCode, redeemManualVoucher } = useSelector((appState: AppState) => {
        return {
            currentView: appState.generalState.currentView,
            voucherCode: appState.generalState.voucherCode,
            redeemManualVoucher: appState.generalState.redeemManualVoucher,
            inLineErrors: appState.generalState.inLineErrors,
        }
    })

    const setVoucherCode = useCallback(
        (payload: string) => {
            dispatch({ type: GeneralStateAction.SET_VOUCHER_CODE, payload })
        },
        [dispatch],
    )

    const setRedeemManualVoucher = useCallback(
        (payload: boolean) => {
            dispatch({ type: GeneralStateAction.SET_REDEEM_MANUAL_VOUCHER, payload })
        },
        [dispatch],
    )

    const onVoucherCodeChange = (code: string): void => {
        setVoucherCode(code)
    }

    useEffect(() => {
        if (!redeemManualVoucher) {
            setVoucherCode('')
        }
    }, [redeemManualVoucher, setVoucherCode])

    return {
        currentView,
        voucherCode,
        onVoucherCodeChange,
        redeemManualVoucher,
        inLineErrors,
        setVoucherCode,
        setRedeemManualVoucher,
    }
}
