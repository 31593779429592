import { Grid, TextField } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PortabilityViewFields } from 'store/PortabilityState/PortabilityState.reducer'
import TestIDs from 'utils/TestIDs'

export interface RenderTextFieldsProps {
    field: PortabilityViewFields
    firstLabel: string
    firstValue: string
    index: number
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    secondLabel: string
    secondValue: string
    firstValueError?: boolean
    secondValueError?: boolean
    phone?: boolean
}
const RenderTextFields = (props: RenderTextFieldsProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        field,
        firstValueError,
        secondValueError,
        firstLabel,
        firstValue,
        index,
        onChange,
        secondLabel,
        secondValue,
        phone,
    } = props

    const contractHolderField = PortabilityViewFields.CONTRACT_HOLDER
    // prettier-ignore
    const handleChange = (str: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
                const regexp = /^[0-9() \+\-\/\b]+$/
                if (phone){
                    if (regexp.test(e.target.value) || e.target.value === '') {
                        onChange(field, str, index, e.target.value)
                    }
                } else {
                    onChange(field, str, index, e.target.value)
                }
        
    }

    return (
        <React.Fragment>
            <Grid item xs={12} md={field === contractHolderField ? 12 : 4}>
                <TextField
                    {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_FIRST_LABEL' + index)}
                    variant={'outlined'}
                    InputProps={firstValueError === true ? { className: 'TextError' } : undefined}
                    error={firstValueError === true}
                    helperText={firstValueError === true && t('error.portabilityDetails.firstValue.' + field)}
                    fullWidth={true}
                    label={firstLabel}
                    value={firstValue}
                    onChange={handleChange('FIRST_LABEL')}
                    type={field === contractHolderField ? 'text' : 'tel'}
                    inputProps={{ maxLength: phone ? 10 : 150 }}
                />
            </Grid>
            <Grid item xs={12} md={field === contractHolderField ? 12 : 8}>
                <TextField
                    {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_SECOND_LABEL' + index)}
                    variant={'outlined'}
                    InputProps={secondValueError === true ? { className: 'TextError' } : undefined}
                    error={secondValueError === true}
                    helperText={secondValueError === true && t('error.portabilityDetails.secondValue' + field)}
                    fullWidth={true}
                    label={secondLabel}
                    value={secondValue}
                    onChange={handleChange('SECOND_LABEL')}
                    type={field === contractHolderField ? 'text' : 'tel'}
                    inputProps={{ maxLength: phone ? 50 : 150 }}
                />
            </Grid>
        </React.Fragment>
    )
}

export default RenderTextFields
