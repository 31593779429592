import { AccountType } from 'graphql/types'
import { PersistState } from 'redux-persist'
import BankDetailsActions, { BankDetailsAction } from './BankDetails.actions'

export interface BankDetailsState {
    accountType: AccountType
    differentAccountHolder: boolean
    consentChecked: boolean
    consentCollectMoneyFromAccountChecked: boolean
    consentSEPAChecked: boolean
    accountHolderData: {
        salutation: string
        title: string
        name: string
        lastName: string
        street: string
        houseNumber: string
        zip: string
        city: string
        company: string
        companyLegalForm: string
        additionalInfoAddress?: string
    }
    iban: string
    transfer: boolean
    isValidating: boolean
    dayOfTransfer: string
    _persist: PersistState
}

export const initialBankDetailsState: BankDetailsState = {
    accountType: AccountType.IBAN,
    consentChecked: false,
    consentCollectMoneyFromAccountChecked: false,
    consentSEPAChecked: false,
    differentAccountHolder: false,
    accountHolderData: {
        salutation: '',
        title: '',
        name: '',
        lastName: '',
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
        company: '',
        companyLegalForm: '',
        additionalInfoAddress: undefined,
    },
    iban: '',
    transfer: false,
    isValidating: false,
    dayOfTransfer: '15',
    _persist: { rehydrated: false, version: 1 } as PersistState,
}

function BankDetailsReducer(
    state: BankDetailsState = initialBankDetailsState,
    action: BankDetailsActions,
): BankDetailsState {
    switch (action.type) {
        case BankDetailsAction.SET_TO_INIT_STATE:
            return initialBankDetailsState
        case BankDetailsAction.SET_BANK_DETAILS_TYPE:
            return {
                ...state,
                transfer: state.accountType === AccountType.IBAN ? true : false,
                iban: state.accountType === AccountType.IBAN ? '' : state.iban,
                accountType: state.accountType === AccountType.IBAN ? AccountType.TRANSFER : AccountType.IBAN,
                consentChecked: false,
                consentCollectMoneyFromAccountChecked: false,
                dayOfTransfer: state.accountType === AccountType.IBAN ? state.dayOfTransfer : '15',
            }
        case BankDetailsAction.SET_CONSENT:
            return {
                ...state,
                consentChecked: !state.consentChecked,
            }
        case BankDetailsAction.SET_BANK_DETAILS_PARTIAL:
            return {
                ...state,
                ...action.payload,
            }
        case BankDetailsAction.SET_DAY_OF_TRANSFER:
            return {
                ...state,
                dayOfTransfer: action.payload,
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_ADDITIONAL_ADDR_INFO:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    additionalInfoAddress: action.payload,
                },
            }
        case BankDetailsAction.SET_DIFFERENT_ACCOUNT_HOLDER:
            return {
                ...state,
                differentAccountHolder: !state.differentAccountHolder,
                consentChecked: false,
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_CITY:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    city: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_ZIP:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    zip: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_HOUSE_NUMBER:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    houseNumber: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_STREET:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    street: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_LAST_NAME:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    lastName: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_NAME:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    name: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_TITLE:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    title: action.payload,
                },
            }
        case BankDetailsAction.SET_ACCOUNT_HOLDER_SALUTATION:
            return {
                ...state,
                accountHolderData: {
                    ...state.accountHolderData,
                    salutation: action.payload,
                },
            }
        case BankDetailsAction.SET_IBAN:
            return {
                ...state,
                iban: action.payload,
            }
        case BankDetailsAction.SET_BANK_DETAILS_STATE:
            return action.payload
        default:
            return state
    }
}

export default BankDetailsReducer
