import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import getEnvConfig from 'utils/getEnvConfig'

const SentryInit = (): Sentry.BrowserOptions => {
    const envConfig = getEnvConfig()
    return {
        dsn: envConfig.SentryDSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: 'Client_ ' + envConfig.SentryENV,
    }
}
export default SentryInit
