import gql from 'graphql-tag'

export default gql`
    query LoadAddresses($input: PostCodeInput!) {
        loadAddresses(input: $input) {
            matches {
                value
                label
                description
                precision
                context
                highlights
            }
        }
    }
`
