import { Box, Grid, Link, Typography } from '@material-ui/core'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { HashRouter, Link as RouterLink } from 'react-router-dom'
import { Address } from '../../store/GeneralState/GeneralState.reducer'
import colors from '../../utils/colors'
import ProductTableCategory, { ProductTableCategoryProps } from '../ProductTableCategory/ProductTableCategory'

export interface ProductTableProps {
    address?: Address
    productCategories: ProductTableCategoryProps[]
    hideAddress?: boolean
    isBasket?: boolean
}

const ProductTable = (props: ProductTableProps): JSX.Element => {
    const { t } = useTranslation()
    const { address, hideAddress, productCategories, isBasket } = props

    const newProductCategories = cloneDeep(productCategories)
    const additionalServiceIndex = newProductCategories.findIndex((pc) =>
        pc.products.find((p) => p.category === 'additionalServiceService'),
    )
    if (additionalServiceIndex !== -1) {
        const productIndex = newProductCategories[additionalServiceIndex].products.findIndex(
            (p) => p.category === 'additionalServiceService',
        )
        if (productIndex !== -1) {
            newProductCategories[additionalServiceIndex].products.splice(productIndex, 1)
            newProductCategories.push({
                title: t('additionalService'),
                products: [productCategories[additionalServiceIndex].products[productIndex]],
            })
        }
    }

    return (
        <Grid container>
            {address && (hideAddress === undefined || hideAddress === false) && (
                <Grid container className={'ProductTableCategory'}>
                    <Grid item xs={12}>
                        <Box paddingBottom={2}>
                            <Typography variant={'h5'} className={'ProductTableHeadline'}>
                                {t('address')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item container className={'ProductTableRow'}>
                            <Grid container item xs alignItems={'center'}>
                                <Typography className={'ProductTableRowText'}>
                                    {`${address.street} ${address.houseNumber}`} <br />
                                    {`${address.zip} ${address.city}`}
                                </Typography>
                            </Grid>
                            <Grid container item xs justifyContent={'flex-end'} alignItems={'flex-end'}>
                                <HashRouter>
                                    <Link component={RouterLink} to={'/'} className={'ProductTableAddressLink'}>
                                        {t('changeAddress')}
                                    </Link>
                                </HashRouter>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {newProductCategories.map((PC: ProductTableCategoryProps, index: number) => (
                <Grid
                    item
                    xs={12}
                    key={PC.title + index}
                    style={
                        address && (hideAddress === undefined || hideAddress === false)
                            ? { borderTop: `1px solid ${colors.midGray}` }
                            : index === 0
                            ? {}
                            : { borderTop: `1px solid ${colors.midGray}` }
                    }
                >
                    <ProductTableCategory {...PC} iconStyle={{ color: colors.basketInfoIcon }} isBasket={isBasket} />
                </Grid>
            ))}
        </Grid>
    )
}

export default ProductTable
