import gql from 'graphql-tag'

export default gql`
    query LoadOrderData($id: String!) {
        loadOrderProgress(id: $id) {
            data
            vzfID
            vzfData {
                selectedProductCategories
                multipleSelectOptionList {
                    key
                    value
                }
                startOfDelivery
                startOfMarketing
                zipcode
                city
                street
                houseNumber
                additionalInfo
            }
        }
    }
`
