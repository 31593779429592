import { AccountType } from 'graphql/types'
import { BankDetailsState } from './BankDetails.reducer'

export enum BankDetailsAction {
    DEFAULT = 'DEFAULT',
    SET_DIFFERENT_ACCOUNT_HOLDER = 'SET_DIFFERENT_ACCOUNT_HOLDER',
    SET_CONSENT = 'SET_CONSENT',
    SET_ACCOUNT_HOLDER_SALUTATION = 'SET_ACCOUNT_HOLDER_SALUTATION',
    SET_ACCOUNT_HOLDER_TITLE = 'SET_ACCOUNT_HOLDER_TITLE',
    SET_ACCOUNT_HOLDER_NAME = 'SET_ACCOUNT_HOLDER_NAME',
    SET_ACCOUNT_HOLDER_LAST_NAME = 'SET_ACCOUNT_HOLDER_LAST_NAME',
    SET_ACCOUNT_HOLDER_STREET = 'SET_ACCOUNT_HOLDER_STREET',
    SET_ACCOUNT_HOLDER_HOUSE_NUMBER = 'SET_ACCOUNT_HOLDER_HOUSE_NUMBER',
    SET_ACCOUNT_HOLDER_ZIP = 'SET_ACCOUNT_HOLDER_ZIP',
    SET_ACCOUNT_HOLDER_CITY = 'SET_ACCOUNT_HOLDER_CITY',
    SET_ACCOUNT_HOLDER_ADDITIONAL_ADDR_INFO = 'SET_ACCOUNT_HOLDER_ADDITIONAL_ADDR_INFO',
    SET_IBAN = 'SET_IBAN',
    SET_BANK_DETAILS_STATE = 'SET_BANK_DETAILS_STATE',
    SET_BANK_DETAILS_TYPE = 'SET_BANK_DETAILS_TYPE',
    SET_DAY_OF_TRANSFER = 'SET_DAY_OF_TRANSFER',
    SET_BANK_DETAILS_PARTIAL = 'SET_BANK_DETAILS_PARTIAL',
    SET_TO_INIT_STATE = 'SET_BANK_DETAILS_TO_INIT_STATE',
}

type BankDetailsActions =
    | { payload: null; type: BankDetailsAction.SET_TO_INIT_STATE }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_SALUTATION }
    | { payload: Partial<BankDetailsState>; type: BankDetailsAction.SET_BANK_DETAILS_PARTIAL }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_ADDITIONAL_ADDR_INFO }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_CITY }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_HOUSE_NUMBER }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_LAST_NAME }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_NAME }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_STREET }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_TITLE }
    | { payload: string; type: BankDetailsAction.SET_ACCOUNT_HOLDER_ZIP }
    | { payload: string; type: BankDetailsAction.SET_IBAN }
    | { payload: string; type: BankDetailsAction.SET_DAY_OF_TRANSFER }
    | { payload: boolean; type: BankDetailsAction.SET_DIFFERENT_ACCOUNT_HOLDER }
    | { type: BankDetailsAction.DEFAULT }
    | { payload: boolean; type: BankDetailsAction.SET_CONSENT }
    | { payload: AccountType; type: BankDetailsAction.SET_BANK_DETAILS_TYPE }
    | { payload: BankDetailsState; type: BankDetailsAction.SET_BANK_DETAILS_STATE }

export default BankDetailsActions
