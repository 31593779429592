import { Box, ButtonBase, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
    PortabilityContractHolder,
    PortabilityTelephone,
    PortabilityViewFields,
} from 'store/PortabilityState/PortabilityState.reducer'
import TestIDs from 'utils/TestIDs'

export interface AddRemoveButtonProps {
    addOption: (field: PortabilityViewFields) => void
    field: PortabilityViewFields
    isOptionEmpty: (option: PortabilityTelephone | PortabilityContractHolder) => boolean
    objectLength: number
    options: Array<PortabilityTelephone | PortabilityContractHolder>
    removeOption: (field: PortabilityViewFields) => void
}
const AddRemoveButton = (props: AddRemoveButtonProps): JSX.Element => {
    const { addOption, field, isOptionEmpty, options, removeOption, objectLength } = props
    const { t } = useTranslation()
    return (
        <Grid item md={6}>
            {!isOptionEmpty(options[0]) &&
                (options.length < objectLength || isOptionEmpty(options[options.length - 1])) && (
                    <Box textAlign={'left'}>
                        <ButtonBase
                            {...TestIDs.GET_PORTABILITY_DETAILS('BUTTON', field + '_ADD')}
                            onClick={
                                options.length > 1 && isOptionEmpty(options[options.length - 1])
                                    ? (): void => removeOption(field)
                                    : (): void => {
                                          if (options.length < objectLength) {
                                              addOption(field)
                                          }
                                      }
                            }
                        >
                            {options.length > 1 && isOptionEmpty(options[options.length - 1]) ? (
                                <RemoveIcon fontSize={'small'} className={'AddFieldButton'} />
                            ) : (
                                <AddIcon fontSize={'small'} className={'AddFieldButton'} />
                            )}
                            <Typography
                                {...TestIDs.GET_PORTABILITY_DETAILS('BUTTON', field + '_ADD_LABEL')}
                                variant={'body2'}
                                className={'AddFieldButton'}
                            >
                                {options.length > 1 && isOptionEmpty(options[options.length - 1])
                                    ? t('portabilityDetailsStrings.' + field + '.RemoveButtonLabel')
                                    : t('portabilityDetailsStrings.' + field + '.AddButtonLabel')}
                            </Typography>
                        </ButtonBase>
                    </Box>
                )}
        </Grid>
    )
}

export default AddRemoveButton
