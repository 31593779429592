import { ViewType } from '../store/GeneralState/GeneralState.reducer'

export interface GetTestIDReturn {
    [key: string]: string | undefined
}

export const getTestID = (id: string): GetTestIDReturn => {
    if (process.env.NODE_ENV === 'production') return {}
    return {
        'data-testid': id,
    }
}

type BASE_INPUTS = 'BACK_BUTTON' | 'HEADER_TYPOGRAPHY' | 'SUBHEADER_TYPOGRAPHY' | 'SUBMIT_BUTTON'

const TestIDs = {
    GET_AVAILABILITY_CHECK: (
        input:
            | BASE_INPUTS
            | 'ADDITIONAL_SELECT'
            | 'CITY_INPUT'
            | 'CITY_SELECT'
            | 'FORM'
            | 'HOUSE_NUMBER_INPUT'
            | 'HOUSE_NUMBER_SELECT'
            | 'STREET_INPUT'
            | 'STREET_SELECT'
            | 'ZIP_INPUT'
            | 'VZF_INPUT'
            | 'VZF_CHECK_BUTTON',
    ): GetTestIDReturn => getTestID('AVAILABILITY_CHECK_' + input),
    GET_BANK_DETAILS: (
        input:
            | BASE_INPUTS
            | 'ACCOUNT_HOLDER'
            | 'BANK_DATA'
            | 'CONSENT_CHECKBOX'
            | 'IBAN_INPUT'
            | 'ACCOUNT_NUMBER_INPUT'
            | 'BANK_CODE_INPUT',
    ): GetTestIDReturn => getTestID('BANK_DETAILS_' + input),
    GET_CONFIGURATION: (
        type: 'ITEM' | 'CHECKBOX' | 'RADIO',
        input: 'TITLE' | 'LINK' | 'HELP_TEXT' | 'BUTTON',
        category?: string,
    ): GetTestIDReturn => getTestID('CONFIGURATION_' + type + '_' + input + (category ? '_' + category : '')),
    GET_CONTACT_DATA: (
        input:
            | BASE_INPUTS
            | 'SALUTATION'
            | 'TITLE'
            | 'NAME'
            | 'LAST_NAME'
            | 'ZIP'
            | 'CITY'
            | 'STREET'
            | 'HOUSE_NUMBER'
            | 'EMAIL'
            | 'BIRTH_DATE'
            | 'ADDITIONAL_ADDR_INFO'
            | 'TELEPHONE'
            | 'MOBILE_PHONE'
            | 'AUTO_COMPLETE'
            | 'COMPANY'
            | 'COMPANY_LEGALFORM'
            | 'COMPANY_REGISTRATION'
            | 'COMPANY_LOCATION'
            | 'COMPANY_TAX_ID'
            | 'TOGGLE'
            | 'NO_CUSTOMER_EMAIL',
        category: 'PERSONAL' | 'DELIVERY' | 'BILLING' | 'ACCOUNT_HOLDER',
    ): GetTestIDReturn => getTestID('CONTACT_DATA_' + input + '_' + category),
    GET_DESIRED_DATE: (input: BASE_INPUTS | 'DATE_PICKER'): GetTestIDReturn => getTestID('DESIRED_DATE_' + input),
    GET_MANUAL_VOUCHER_REDEMPTION: (
        input: BASE_INPUTS | 'VOUCHER_REDEMPTION_TRUE' | 'VOUCHER_REDEMPTION_FALSE' | 'TEXTFIELD',
    ): GetTestIDReturn => getTestID('GET_MANUAL_VOUCHER_REDEMPTION_' + input),
    GET_HEADER_COMPONENT: (input: '' | 'HELP_BUTTON' | 'LOGO' | 'LOGOICON_BUTTON'): GetTestIDReturn =>
        getTestID('HEADER_COMPONENT' + (input !== '' ? '_' + input : '')),
    GET_INSTALLATION_DETAILS: (
        input: BASE_INPUTS | 'TEXTFIELD' | 'RADIO' | 'CHECK' | 'VIDEO',
        category?: string,
    ): GetTestIDReturn => getTestID('INSTALLATION_DETAILS_' + input + (category ? '_' + category : '')),
    GET_LANDLINE_CONTRACT_OPTIONS: (input: BASE_INPUTS): GetTestIDReturn =>
        getTestID('LANDLINE_CONTRACT_OPTIONS_' + input),
    GET_OPTION_ITEM: (input: string): GetTestIDReturn => getTestID('OPTION_ITEM_COMPONENT_' + input),
    GET_ORDER_CONFIRMATION: (input: BASE_INPUTS | 'IMAGE' | 'TEXT_1' | 'TEXT_2'): GetTestIDReturn =>
        getTestID('ORDER_CONFIRMATION_' + input),
    GET_ORDER_OVERVIEW: (input: BASE_INPUTS | 'CHECKBOX', category?: string): GetTestIDReturn =>
        getTestID('ORDER_OVERVIEW_' + input + (category ? '_' + category : '')),
    GET_PORTABILITY_DETAILS: (
        input: BASE_INPUTS | 'RADIO' | 'DROPDOWN' | 'INPUT' | 'BUTTON' | 'AUTO_COMPLETE_PROVIDER',
        category?: string,
    ): GetTestIDReturn => getTestID('PORTABILITY_DETAILS_' + input + (category ? '_' + category : '')),
    GET_PRODUCT_CATEGORIES_SELECTION: (
        input: BASE_INPUTS | 'PRODUCT_CATEGORY_CARD',
        category?: string,
    ): GetTestIDReturn => getTestID('PRODUCT_CATEGORIES_SELECTION_' + input + (category ? '_' + category : '')),
    GET_VZF: (input: BASE_INPUTS | 'VZF_ID' | 'GET_VZF_VIEW_EMAIL' | 'GET_VZF_VIEW_SEND'): GetTestIDReturn =>
        getTestID('VZF_VIEW_' + input),
    GET_PRODUCT_SELECTION: (input: BASE_INPUTS | 'SWITCH_MONTH', category?: string): GetTestIDReturn =>
        getTestID('PRODUCT_SELECTION_' + input + (category ? '_' + category : '')),
    GET_OPTIONS_CONFIGURATION: (input: BASE_INPUTS, category?: string): GetTestIDReturn =>
        getTestID('OPTIONS_CONFIGURATION_' + input + (category ? '_' + category : '')),
    GET_TELEPHONE_CONFIGURE: (input: BASE_INPUTS): GetTestIDReturn => getTestID('TELEPHONE_CONFIGURE_' + input),
    GET_TELEVISION_CONFIGURE: (input: BASE_INPUTS): GetTestIDReturn => getTestID('TELEVISION_CONFIGURE_' + input),
    GET_TELEVISION_CONFIGURE_OPTIONS: (input: BASE_INPUTS): GetTestIDReturn =>
        getTestID('TELEVISION_CONFIGURE_OPTIONS_' + input),
    GET_TEST_PAGE: (input: BASE_INPUTS): GetTestIDReturn => getTestID('TEST_PAGE_' + input),
    GET_AUTOCOMPLETE: (input: 'SEARCH_FIELD'): GetTestIDReturn => getTestID('AUTO_COMPLETE_' + input),
}

export const getTestIDOnViewType = (viewType: ViewType, input: string, category?: string): GetTestIDReturn => {
    switch (viewType) {
        case ViewType.AVAILABILITY_CHECK:
            return TestIDs.GET_AVAILABILITY_CHECK(
                input as
                    | BASE_INPUTS
                    | 'ADDITIONAL_SELECT'
                    | 'CITY_INPUT'
                    | 'CITY_SELECT'
                    | 'FORM'
                    | 'HOUSE_NUMBER_INPUT'
                    | 'HOUSE_NUMBER_SELECT'
                    | 'STREET_INPUT'
                    | 'STREET_SELECT'
                    | 'ZIP_INPUT',
            )
        case ViewType.VZF_VIEW:
            return TestIDs.GET_VZF(input as BASE_INPUTS | 'GET_VZF_VIEW_EMAIL' | 'GET_VZF_VIEW_SEND')
        case ViewType.CONTACT_DATA:
            return TestIDs.GET_CONTACT_DATA(
                input as
                    | BASE_INPUTS
                    | 'SALUTATION'
                    | 'TITLE'
                    | 'NAME'
                    | 'LAST_NAME'
                    | 'ZIP'
                    | 'CITY'
                    | 'STREET'
                    | 'HOUSE_NUMBER'
                    | 'EMAIL'
                    | 'BIRTH_DATE'
                    | 'TELEPHONE'
                    | 'MOBILE_PHONE'
                    | 'TOGGLE',
                category ? (category as 'PERSONAL' | 'DELIVERY' | 'BILLING') : 'PERSONAL',
            )
        case ViewType.DESIRED_DATE:
            return TestIDs.GET_DESIRED_DATE(input as BASE_INPUTS | 'DATE_PICKER')
        case ViewType.MANUAL_VOUCHER_REDEMPTION:
            return TestIDs.GET_MANUAL_VOUCHER_REDEMPTION(
                input as BASE_INPUTS | 'VOUCHER_REDEMPTION_TRUE' | 'VOUCHER_REDEMPTION_FALSE' | 'TEXTFIELD',
            )
        case ViewType.LANDLINE_CONTRACT_OPTIONS:
            return TestIDs.GET_LANDLINE_CONTRACT_OPTIONS(input as BASE_INPUTS)
        case ViewType.PRODUCT_CATEGORIES_SELECTION:
            return TestIDs.GET_PRODUCT_CATEGORIES_SELECTION(input as BASE_INPUTS)
        case ViewType.BANK_DETAILS:
            return TestIDs.GET_BANK_DETAILS(
                input as
                    | BASE_INPUTS
                    | 'ACCOUNT_HOLDER'
                    | 'BANK_DATA'
                    | 'CONSENT_CHECKBOX'
                    | 'IBAN_INPUT'
                    | 'ACCOUNT_NUMBER_INPUT'
                    | 'BANK_CODE_INPUT',
            )
        case ViewType.INSTALLATION_DETAILS:
            return TestIDs.GET_INSTALLATION_DETAILS(
                input as BASE_INPUTS | 'TEXTFIELD' | 'RADIO' | 'CHECK' | 'VIDEO',
                category ? (category as string) : '',
            )
        case ViewType.PRODUCT_SELECTION:
            return TestIDs.GET_PRODUCT_SELECTION(input as BASE_INPUTS | 'SWITCH_MONTH', category ? category : undefined)
        case ViewType.PORTABILITY_DETAILS:
            return TestIDs.GET_PORTABILITY_DETAILS(
                input as BASE_INPUTS | 'RADIO' | 'DROPDOWN' | 'INPUT' | 'BUTTON',
                category ? (category as string) : '',
            )
        case ViewType.OPTIONS_CONFIGURATION:
            return TestIDs.GET_OPTIONS_CONFIGURATION(input as BASE_INPUTS, category ? category : undefined)
        case ViewType.ORDER_OVERVIEW:
            return TestIDs.GET_ORDER_OVERVIEW(input as BASE_INPUTS | 'CHECKBOX', category ? category : undefined)
        default:
            return getTestID(viewType + input)
    }
}

export default TestIDs
