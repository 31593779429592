import { Grid, TextField } from '@material-ui/core'
import PostCodeAddress from 'components/PostCodeAddress/PostCodeAddress'
import { PostCodeDetail } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { ContactDataViewConfiguration } from 'utils/customize'
import { Error, ViewType } from '../../store/GeneralState/GeneralState.reducer'
import TestIDs from '../../utils/TestIDs'
import Dropdown from '../Dropdown'

export interface ContactFieldsProps {
    city: string
    errors?: Error[]
    houseNumber: string
    isAddressDisabled: boolean
    lastName: string
    name: string
    salutation: string
    setCity?: (value: string) => void
    setHouseNumber?: (value: string) => void
    setLastName: (value: string) => void
    setName: (value: string) => void
    setSalutation: (value: string) => void
    setStreet?: (value: string) => void
    setTitle: (value: string) => void
    setZip?: (value: string) => void
    street: string
    testCategory: 'PERSONAL' | 'DELIVERY' | 'BILLING' | 'ACCOUNT_HOLDER'
    title: string
    zip: string

    showAutoComplete?: boolean
    showAdditionalAddressInfo?: boolean
    additionalInfoAddr?: string
    setAdditionalInfoAddr?: (value: string) => void

    company?: string
    setCompany?: (value: string) => void

    companyLegalForm?: string
    setCompanyLegalForm?: (value: string) => void

    companyRegisterEntry?: string
    setCompanyRegistrationEntry?: (value: string) => void

    companyLocation?: string
    setCompanyLocation?: (value: string) => void

    companyId?: string
    setCompanyId?: (value: string) => void
    limitedSalutationOptions?: boolean

    contactDataViewConfiguration?: ContactDataViewConfiguration

    handleAddressSelected?: (data: PostCodeDetail) => void
}

const salutationOptions = ['Herr', 'Frau', 'Divers', 'Keine Angabe']
const moreSalutationOptions = [
    ...salutationOptions,
    'Familie',
    'Eheleute',
    'Frau und Frau',
    'Herr und Frau',
    'Herr und Herr',
]
const ContactFields = (props: ContactFieldsProps): JSX.Element => {
    const { t } = useTranslation()
    const regexp = /^[0-9\b]+$/
    const {
        city,
        errors,
        houseNumber,
        isAddressDisabled,
        lastName,
        name,
        salutation,
        setCity,
        setHouseNumber,
        setLastName,
        setName,
        setSalutation,
        setStreet,
        setTitle,
        setZip,
        street,
        testCategory,
        title,
        zip,
        showAutoComplete,
        showAdditionalAddressInfo,
        additionalInfoAddr,
        setAdditionalInfoAddr,
        company,
        setCompany,
        companyLegalForm,
        setCompanyLegalForm,
        companyRegisterEntry,
        setCompanyRegistrationEntry,
        companyLocation,
        setCompanyLocation,
        companyId,
        setCompanyId,
        limitedSalutationOptions,
        contactDataViewConfiguration,
        handleAddressSelected,
    } = props

    const setAddressSelected = (data: PostCodeDetail) => {
        if (handleAddressSelected) {
            handleAddressSelected(data)
        }
    }

    let enableLegalForm = false
    let enableCompanyRegisterEntry = false
    let enableCompanyLocation = false
    let enableCompanyTaxID = false
    if (contactDataViewConfiguration) {
        enableLegalForm = contactDataViewConfiguration.enableAlternativeLegalForm
        enableCompanyRegisterEntry = contactDataViewConfiguration.enableCompanyRegisterEntry
        enableCompanyLocation = contactDataViewConfiguration.enableCompanyLocation
        enableCompanyTaxID = contactDataViewConfiguration.enableCompanyTaxID
        if (testCategory === 'PERSONAL') {
            enableLegalForm = contactDataViewConfiguration.enableLegalForm
        }
    }

    const getZipErr = (): string => {
        if (errors) {
            switch (testCategory) {
                case 'ACCOUNT_HOLDER':
                    errors.find((err) => {
                        if (
                            err.category === ViewType.BANK_DETAILS &&
                            err.errors.find((error) => error === 'zipMustBe5Digits') !== undefined
                        ) {
                            return 'TextError'
                        }
                    })
                    return ''

                case 'BILLING':
                    errors.find((err) => {
                        if (
                            err.category === ViewType.CONTACT_DATA &&
                            err.errors.find((error) => error === 'billingZipMustBe5Digits') !== undefined
                        ) {
                            return 'TextError'
                        }
                    })
                    return ''

                case 'DELIVERY':
                    errors.find((err) => {
                        if (
                            err.category === ViewType.CONTACT_DATA &&
                            err.errors.find((error) => error === 'deliveryZipMustBe5Digits') !== undefined
                        ) {
                            return 'TextError'
                        }
                    })
                    return ''
                default:
                    return ''
            }
        } else {
            return ''
        }
    }

    return (
        <Grid container item spacing={1}>
            <Grid item xs={12} md={6}>
                <Dropdown
                    testIdentifier={{
                        input: 'SALUTATION',
                        viewType: ViewType.CONTACT_DATA,
                        category: testCategory,
                    }}
                    label={t('contactDataStrings.salutation')}
                    options={limitedSalutationOptions ? salutationOptions : moreSalutationOptions}
                    value={salutation}
                    onChange={(e): void => {
                        setSalutation(e.target.value as string)
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    {...TestIDs.GET_CONTACT_DATA('TITLE', testCategory)}
                    fullWidth={true}
                    label={t('contactDataStrings.title')}
                    variant={'outlined'}
                    value={title}
                    onChange={(e): void => setTitle(e.currentTarget.value)}
                    inputProps={{ maxLength: 30 }}
                />
            </Grid>
            {company !== undefined && setCompany && (
                <Grid item xs={12}>
                    <TextField
                        {...TestIDs.GET_CONTACT_DATA('COMPANY', testCategory)}
                        fullWidth={true}
                        label={t('contactDataStrings.companyName') + ' *'}
                        variant={'outlined'}
                        value={company}
                        onChange={(e): void => setCompany(e.currentTarget.value)}
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
            )}
            {enableLegalForm === true &&
                company !== undefined &&
                companyLegalForm !== undefined &&
                setCompanyLegalForm && (
                    <Grid item xs={12}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('COMPANY_LEGALFORM', testCategory)}
                            fullWidth={true}
                            label={t('contactDataStrings.companyLegalForm') + ' *'}
                            variant={'outlined'}
                            value={companyLegalForm}
                            onChange={(e): void => setCompanyLegalForm(e.currentTarget.value)}
                            inputProps={{ maxLength: 30 }}
                        />
                    </Grid>
                )}
            {enableCompanyRegisterEntry === true && companyRegisterEntry !== undefined && setCompanyRegistrationEntry && (
                <Grid item xs={12}>
                    <TextField
                        {...TestIDs.GET_CONTACT_DATA('COMPANY_REGISTRATION', testCategory)}
                        fullWidth={true}
                        label={t('contactDataStrings.companyRegisterEntry')}
                        variant={'outlined'}
                        value={companyRegisterEntry}
                        onChange={(e): void => setCompanyRegistrationEntry(e.currentTarget.value)}
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
            )}

            {enableCompanyLocation === true && companyLocation !== undefined && setCompanyLocation && (
                <Grid item xs={12}>
                    <TextField
                        {...TestIDs.GET_CONTACT_DATA('COMPANY_LEGALFORM', testCategory)}
                        fullWidth={true}
                        label={t('contactDataStrings.companyLocation') + ' *'}
                        variant={'outlined'}
                        value={companyLocation}
                        onChange={(e): void => setCompanyLocation(e.currentTarget.value)}
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
            )}
            {enableCompanyTaxID === true && companyId !== undefined && setCompanyId && (
                <Grid item xs={12}>
                    <TextField
                        {...TestIDs.GET_CONTACT_DATA('COMPANY_TAX_ID', testCategory)}
                        fullWidth={true}
                        label={t('contactDataStrings.companyTaxId') + ' *'}
                        variant={'outlined'}
                        value={companyId}
                        onChange={(e): void => setCompanyId(e.currentTarget.value)}
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField
                    {...TestIDs.GET_CONTACT_DATA('NAME', testCategory)}
                    fullWidth={true}
                    label={t('contactDataStrings.name')}
                    variant={'outlined'}
                    value={name}
                    placeholder={'Max'}
                    onChange={(e): void => setName(e.currentTarget.value)}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...TestIDs.GET_CONTACT_DATA('LAST_NAME', testCategory)}
                    fullWidth={true}
                    label={t('contactDataStrings.lastName')}
                    variant={'outlined'}
                    value={lastName}
                    placeholder={'Mustermann'}
                    onChange={(e): void => setLastName(e.currentTarget.value)}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            {showAutoComplete !== true && (
                <>
                    <Grid item xs={12} md={10}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('STREET', testCategory)}
                            fullWidth={true}
                            label={isAddressDisabled ? t('street') : t('street') + ' *'}
                            variant={'outlined'}
                            value={street}
                            disabled={isAddressDisabled}
                            onChange={(e) => {
                                if (setStreet) {
                                    setStreet(e.currentTarget.value)
                                }
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('HOUSE_NUMBER', testCategory)}
                            fullWidth={true}
                            label={isAddressDisabled ? t('number') : t('number') + ' *'}
                            variant={'outlined'}
                            value={houseNumber}
                            disabled={isAddressDisabled}
                            onChange={(e) => {
                                if (setHouseNumber) {
                                    setHouseNumber(e.currentTarget.value)
                                }
                            }}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('ZIP', testCategory)}
                            fullWidth={true}
                            label={isAddressDisabled ? t('ZIP') : t('ZIP') + ' *'}
                            variant={'outlined'}
                            value={zip}
                            disabled={isAddressDisabled}
                            onChange={(e) => {
                                if (
                                    setZip &&
                                    ((e.currentTarget.value !== '' && regexp.test(e.currentTarget.value)) ||
                                        e.currentTarget.value === '')
                                ) {
                                    setZip(e.currentTarget.value)
                                }
                            }}
                            inputProps={{ maxLength: 10, className: getZipErr() }}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('CITY', testCategory)}
                            fullWidth={true}
                            label={isAddressDisabled ? t('city') : t('city') + ' *'}
                            variant={'outlined'}
                            value={city}
                            disabled={isAddressDisabled}
                            onChange={(e) => {
                                if (setCity) {
                                    setCity(e.currentTarget.value)
                                }
                            }}
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                </>
            )}
            {showAutoComplete === true && (
                <Grid item xs>
                    <PostCodeAddress
                        data={{ city: city, houseNumber: houseNumber, street: street, zipcode: zip }}
                        handleAddressSelect={setAddressSelected}
                        {...TestIDs.GET_CONTACT_DATA('AUTO_COMPLETE', testCategory)}
                    />
                </Grid>
            )}
            {showAdditionalAddressInfo === true && (
                <Grid item xs={12}>
                    <TextField
                        {...TestIDs.GET_CONTACT_DATA('ADDITIONAL_ADDR_INFO', testCategory)}
                        fullWidth={true}
                        label={t('additionalInfoAddress')}
                        variant={'outlined'}
                        value={additionalInfoAddr ? additionalInfoAddr : ''}
                        onChange={(e) => {
                            if (setAdditionalInfoAddr) {
                                setAdditionalInfoAddr(e.currentTarget.value)
                            }
                        }}
                        inputProps={{ maxLength: 150 }}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default ContactFields
