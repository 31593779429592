import { Box, Link, TooltipProps, Typography } from '@material-ui/core'
import { BillMode, VoucherCurrency } from 'graphql/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import { getMultiplicator } from 'utils/stateToPDFData'
import { formatCurrency } from '../../utils/numberFormat'
import InfoTooltip from '../InfoTooltip'

export interface PriceDescriptionVoucherProps {
    oneTime: boolean
    currency: VoucherCurrency
    month: number
}

export interface PriceDescriptionProps {
    billMode: BillMode
    newPrice: number
    isBasket?: boolean
    isMonthlyIncrements?: boolean
    oldPrice?: number
    info?: Pick<TooltipProps, 'title'>
    datasheet?: string
    description?: string[]
    iconStyle?: React.CSSProperties
    multiple?: number
    voucherData?: PriceDescriptionVoucherProps
}

const PriceDescription = (props: PriceDescriptionProps): JSX.Element => {
    const { t } = useTranslation()
    const { multiple, iconStyle, oldPrice, newPrice, info, datasheet, description, isBasket, billMode, voucherData } =
        props

    let shortBillmode = billMode.toString()
    if (isBasket === false && billMode === BillMode.RECURRING_MONTHLY) {
        shortBillmode += '_SHORT'
    }

    const { showBasketInfoIcons } = useSelector((appState: AppState) => {
        return {
            showBasketInfoIcons: appState.generalState.customizeJsData?.globalConfiguration.showBasketInfoIcons,
        }
    })
    const hideBillMode = billMode !== BillMode.IGNORE
    let showInfoIcon = true
    if (isBasket === true) showInfoIcon = showBasketInfoIcons === true
    const multiplicator = getMultiplicator(multiple)

    return (
        <>
            <Box width={1} style={{ justifyContent: 'flex-end' }} display={'flex'}>
                {oldPrice !== undefined && (
                    <Box pr={1} className={'OldPriceContainer'} display={'flex'} alignItems={'center'}>
                        <Typography className={'OldPrice'}>{formatCurrency(oldPrice * multiplicator)}</Typography>
                    </Box>
                )}
                {billMode !== BillMode.VOUCHER && (
                    <Box pr={1} display={'flex'} alignItems={'center'} justifyContent="center">
                        <Typography className={'NewPrice'}>{formatCurrency(newPrice * multiplicator)}</Typography>
                    </Box>
                )}
                {billMode === BillMode.VOUCHER && voucherData && (
                    <Box pr={1} display={'flex'} alignItems={'center'} justifyContent="center">
                        <Typography className={'NewPrice'}>
                            -{voucherData.currency === VoucherCurrency.EURO && formatCurrency(newPrice)}
                            {voucherData.currency === VoucherCurrency.PERCENT && newPrice + '%'}
                        </Typography>
                    </Box>
                )}

                {isBasket === false && hideBillMode && (
                    <Box alignItems="center">
                        <Typography
                            variant="body1"
                            className={'BillMode'}
                            style={{ fontSize: 12, paddingTop: 15, paddingRight: 7 }}
                            dangerouslySetInnerHTML={{ __html: t(shortBillmode) }}
                        />
                    </Box>
                )}
                {info && showInfoIcon && (
                    <Box alignItems={'center'}>
                        <InfoTooltip title={info.title} iconStyle={iconStyle} />
                    </Box>
                )}
                {datasheet && showInfoIcon && (
                    <Box alignItems={'center'}>
                        <Link href={datasheet} target={'_blank'}>
                            <InfoTooltip title="Produktinformationsblatt" iconStyle={iconStyle} />
                        </Link>
                    </Box>
                )}
            </Box>
            {isBasket !== false && hideBillMode && (
                <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Typography
                        variant={'subtitle1'}
                        className={'PriceDescriptionText'}
                        dangerouslySetInnerHTML={{ __html: t(billMode) }}
                    />
                </Box>
            )}
            <Box width={1} className={'PriceDescriptionContainer'}>
                {description &&
                    description.map((value, index) => (
                        <Typography
                            key={index}
                            variant={'subtitle1'}
                            className={'PriceDescriptionText'}
                            dangerouslySetInnerHTML={{ __html: t(value, { month: voucherData?.month ?? '0' }) }}
                        />
                    ))}
            </Box>
        </>
    )
}

export default PriceDescription
