import { ProductCategory } from '../../graphql/types'
import { Page, ViewType } from '../../store/GeneralState/GeneralState.reducer'
import { getSelectedProductCategoryFromData } from './basketCalculation'
import { pathToViewType } from './pathToViewType'

export const getSubmitButtonTextForProductBasedViews = (
    pagesList: Page[],
    currentPage: number,
    availableProductCategories: ProductCategory[],
): { productCategoryType: string; selectionStep: string } | string => {
    if (availableProductCategories.length > 0) {
        const nextPath = pagesList[currentPage + 1] !== undefined ? pagesList[currentPage + 1].path : ''
        if (nextPath !== '') {
            switch (pathToViewType(nextPath)) {
                case ViewType.PRODUCT_SELECTION:
                    {
                        const productCategoryId = nextPath.split('/')[2]
                        if (productCategoryId !== '') {
                            const productCategoryFromData = getSelectedProductCategoryFromData(
                                availableProductCategories,
                                productCategoryId,
                            )
                            if (productCategoryFromData) {
                                const productCategoryType = productCategoryFromData.identifier
                                return {
                                    productCategoryType: productCategoryType,
                                    selectionStep: 'productSelection',
                                }
                            }
                        }
                    }
                    break
                case ViewType.OPTIONS_CONFIGURATION:
                    {
                        // If the next view is an OptionsConfiguration, we currently are on ProductSelection or OptionsConfiguration
                        const currentViewType = pathToViewType(pagesList[currentPage].path)
                        if (currentViewType !== ViewType.OPTIONS_CONFIGURATION) {
                            /**
                             *  If the current view is not OptionsConfiguration, we will have a ProductCategoryId in the current path then we can check it's type
                             *  if the type is not combi, we want to go to the type options configuration
                             *  if the type is combi, we want to check on the next paths productType and we want to go to that type options configuration
                             */
                            const productCategoryId = pagesList[currentPage].path.split('/')[2]
                            if (productCategoryId !== '') {
                                const productCategoryFromData = getSelectedProductCategoryFromData(
                                    availableProductCategories,
                                    productCategoryId,
                                )
                                if (productCategoryFromData) {
                                    const productCategoryType = productCategoryFromData.identifier
                                    if (productCategoryType !== 'combi') {
                                        return {
                                            productCategoryType: productCategoryType,
                                            selectionStep: 'options',
                                        }
                                    } else {
                                        const nextProductCategoryType = nextPath.split('/')[4]
                                        const optionsType = nextPath.split('/')[2]
                                        return {
                                            productCategoryType: nextProductCategoryType,
                                            selectionStep: 'options' + (optionsType === 'hardware' ? '_hardware' : ''),
                                        }
                                    }
                                }
                            }
                        } else {
                            /**
                             * If the current view is an OptionsConfiguration, we are in a combi product, so we just take nextPath's productCategoryType
                             */
                            const parts = nextPath.split('/')
                            const nextProductCategoryType = parts[4]
                            const optionsType = parts[2]

                            return {
                                productCategoryType: nextProductCategoryType,
                                selectionStep: 'options' + (optionsType === 'hardware' ? '_hardware' : ''),
                            }
                        }
                    }
                    break
                default:
                    return 'continue'
            }
        }
    }
    return ''
}
