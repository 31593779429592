import { Box, Grid, TextField } from '@material-ui/core'
import ConfigurationItem from 'components/ConfigurationItem'
import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import { OptionType, ViewType } from 'store/GeneralState/GeneralState.reducer'
import TestIDs from 'utils/TestIDs'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { useManualVoucherRedemptionReducer } from './useManualVoucherRedemptionReducer'

const ManualVoucherRedemption = (): JSX.Element => {
    const { t } = useTranslation()

    const { currentView, setRedeemManualVoucher, inLineErrors, setVoucherCode, redeemManualVoucher, voucherCode } =
        useManualVoucherRedemptionReducer()

    const radioOptions: ConfigurationItemOption[] = [
        {
            columnValue: 12,
            label: t('manualVoucherRedemptionStrings.noVoucherRedemption'),
            mobileColumnValue: 12,
            value: '0001',
        },
        {
            columnValue: 12,
            label: t('manualVoucherRedemptionStrings.voucherRedemption'),
            mobileColumnValue: 12,
            value: '0002',
        },
    ]

    const [userInfo] = useUserInfo()

    return (
        <ViewWrapper
            disabledSubmit={redeemManualVoucher && voucherCode === ''}
            header={getRoleAwareTranslationKey(userInfo, 'manualVoucherRedemptionHeader')}
            subHeader={getRoleAwareTranslationKey(userInfo, 'manualVoucherRedemptionSubheader')}
            viewType={currentView}
            errorCategory={ViewType.MANUAL_VOUCHER_REDEMPTION}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ConfigurationItem
                        option={radioOptions[0]}
                        type={OptionType.RADIO}
                        paddingRight={'0px'}
                        selected={!redeemManualVoucher}
                        onSelect={(): void => {
                            setRedeemManualVoucher(false)
                        }}
                    />
                    <Box mt={1}>
                        <ConfigurationItem
                            option={radioOptions[1]}
                            type={OptionType.RADIO}
                            paddingRight={'0px'}
                            selected={redeemManualVoucher}
                            onSelect={(): void => {
                                setRedeemManualVoucher(true)
                            }}
                        />
                    </Box>
                    {redeemManualVoucher && (
                        <Box mt={1}>
                            <TextField
                                {...TestIDs.GET_MANUAL_VOUCHER_REDEMPTION('TEXTFIELD')}
                                variant={'outlined'}
                                fullWidth={true}
                                inputProps={{
                                    className: inLineErrors.includes('voucherCode') ? 'TextError' : '',
                                }}
                                error={inLineErrors.includes('voucherCode')}
                                helperText={
                                    inLineErrors.includes('voucherCode') &&
                                    t('error.' + ViewType.MANUAL_VOUCHER_REDEMPTION + '.voucherCodeInvalid')
                                }
                                label={t('manualVoucherRedemptionStrings.voucherCodeInput')}
                                value={voucherCode}
                                onChange={(e): void => {
                                    setVoucherCode(e.target.value)
                                }}
                                type={'text'}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </ViewWrapper>
    )
}

export default ManualVoucherRedemption
