import { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { Page, SelectedProductCategory, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { OptionItemContent } from '../../components/OptionItem/OptionItem'
import { SwitchOfferOption } from '../../components/SwitchMonth/SwitchMonth'
import { ProductCategory } from '../../graphql/types'
import { getSelectedProductCategoryFromData } from '../../utils/testable/basketCalculation'
import { productsToRadioOptionGroup } from '../../utils/testable/productsToRadioOptionGroup'
import { switchMonthCalculation } from '../../utils/testable/switchMonthCalculation'

interface ProductSelectionReducerReturn {
    availableProductCategories: ProductCategory[]
    currentView: ViewType
    selectedProductCategoryFromData: ProductCategory | undefined
    monthOptions: SwitchOfferOption[]
    selectedContractLength: number
    setSelectedContractLength: Dispatch<SetStateAction<number>>
    setSelectedProduct: (payload: { productCategoryId: string; productId: string; B2B: boolean }) => void
    productsToShow: OptionItemContent[]
    selectedProductCategory: SelectedProductCategory | undefined
    pagesList: Page[]
    currentPage: number
    disabledSubmit: boolean
}

export const useProductSelectionReducer: () => ProductSelectionReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { B2B } = useURLParams()
    const [selectedProductCategoryFromData, setSelectedProductCategoryFromData] = useState<ProductCategory>()
    const [selectedContractLength, setSelectedContractLength] = useState(0)
    const [productsToShow, setProductsToShow] = useState<Array<OptionItemContent>>([])
    const [monthOptions, setMonthOptions] = useState<Array<SwitchOfferOption>>(
        switchMonthCalculation(selectedProductCategoryFromData),
    )

    const history = useHistory()

    const { availableProductCategories, currentView, selectedProductCategories, currentPage, pagesList } = useSelector(
        (appState: AppState) => {
            return {
                availableProductCategories: appState.generalState.availableProductCategories,
                currentView: appState.generalState.currentView,
                selectedProductCategories: appState.generalState.selectedProductCategories,
                pagesList: appState.generalState.pagesList,
                currentPage: appState.generalState.currentPage,
            }
        },
    )

    const setSelectedProduct = useCallback(
        (payload: { productCategoryId: string; productId: string; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT, payload })
        },
        [dispatch],
    )

    const disabledSubmit = useMemo(() => {
        const selectedProductCategory = selectedProductCategories.find(
            (value) => value.id === history.location.pathname.split('/')[2],
        )
        if (selectedProductCategory) {
            return !selectedProductCategory.selectedProduct
        }
        return true
    }, [selectedProductCategories, history.location.pathname])

    useLayoutEffect(() => {
        const productCategoryID = history.location.pathname.split('/')[2]
        if (
            productCategoryID !== '' &&
            selectedProductCategories.find((value) => value.id === productCategoryID) !== undefined
        ) {
            const productCategoryFromData = getSelectedProductCategoryFromData(
                availableProductCategories,
                productCategoryID,
            )
            if (productCategoryFromData) {
                setSelectedProductCategoryFromData(productCategoryFromData)
                const monthOptions = switchMonthCalculation(productCategoryFromData)
                setMonthOptions(monthOptions)
                setSelectedContractLength(0)
                const productsToShow = productCategoryFromData.products.filter((product) => {
                    if (monthOptions.length > 0) {
                        return isSelectedMonth(monthOptions[0].title, product.subCategory)
                    }
                    return true
                })
                setProductsToShow(productsToRadioOptionGroup(productsToShow.length > 0 ? productsToShow : [], B2B))
            }
        }
    }, [history.location.pathname])

    useEffect(() => {
        if (selectedProductCategoryFromData) {
            const productsToShow = selectedProductCategoryFromData.products.filter((product) => {
                if (monthOptions.length > 0) {
                    return isSelectedMonth(monthOptions[selectedContractLength].title, product.subCategory)
                }
                return true
            })
            setProductsToShow(productsToRadioOptionGroup(productsToShow.length > 0 ? productsToShow : [], B2B))
        }
    }, [selectedContractLength, monthOptions, selectedProductCategoryFromData])

    return {
        availableProductCategories,
        currentView,
        selectedProductCategoryFromData,
        monthOptions,
        selectedContractLength,
        setSelectedContractLength,
        setSelectedProduct,
        productsToShow,
        selectedProductCategory: selectedProductCategories.find(
            (value) => value.id === history.location.pathname.split('/')[2],
        ),
        currentPage,
        pagesList,
        disabledSubmit,
    }
}

const isSelectedMonth = (monthOption: string, subCategory?: string | null | undefined): boolean => {
    if (!subCategory || subCategory.trim().length === 0) return true
    const subCategories = JSON.parse(subCategory)
    return subCategories.name === monthOption
}
