import {
    PortabilityAddress,
    PortabilityContractHolder,
    PortabilityState,
    PortabilityTelephone,
} from './PortabilityState.reducer'

export enum PortabilityStateAction {
    SET_ADDRESS = 'SET_ADDRESS',
    SET_CONTRACT_HOLDER_OPTIONS = 'SET_CONTRACT_HOLDER_OPTIONS',
    SET_END_OF_CONTRACT = 'SET_END_OF_CONTRACT',
    SET_PHONE_OPTIONS = 'SET_PHONE_OPTIONS',
    SET_PROVIDER_OPTIONS = 'SET_PROVIDER_OPTIONS',
    SET_SELECTED_PROVIDER = 'SET_SELECTED_PROVIDER',
    SET_SELECTED_RADIO = 'SET_SELECTED_RADIO',
    SET_PORTABILITY_STATE = 'SET_PORTABILITY_STATE',
    SET_TO_INIT_STATE = 'SET_PORTABILITY_STATE_TO_INIT',
}
type PortabilityStateActions =
    | { payload: null; type: PortabilityStateAction.SET_TO_INIT_STATE }
    | { payload: PortabilityAddress; type: PortabilityStateAction.SET_ADDRESS }
    | { payload: PortabilityContractHolder[]; type: PortabilityStateAction.SET_CONTRACT_HOLDER_OPTIONS }
    | { payload: string; type: PortabilityStateAction.SET_END_OF_CONTRACT }
    | { payload: PortabilityTelephone[]; type: PortabilityStateAction.SET_PHONE_OPTIONS }
    | { payload: string[]; type: PortabilityStateAction.SET_PROVIDER_OPTIONS }
    | { payload: string; type: PortabilityStateAction.SET_SELECTED_PROVIDER }
    | { payload: string[]; type: PortabilityStateAction.SET_SELECTED_RADIO }
    | { payload: PortabilityState; type: PortabilityStateAction.SET_PORTABILITY_STATE }

export default PortabilityStateActions
