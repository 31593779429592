import { format, parse } from 'date-fns'

export const toCRMDate = (s: string): string => {
    const b = parse(s, 'dd.MM.yyyy', new Date())
    return format(b, 'yyyy-MM-dd')
}

export const revertCRMDate = (s: string): string => {
    const b = parse(s, 'yyyy-MM-dd', new Date())
    return format(b, 'dd.MM.yyyy')
}
