import { SwitchOfferOption } from 'components/SwitchMonth/SwitchMonth'
import { ProductCategory } from '../../graphql/types'

export const switchMonthCalculation = (productCategory: ProductCategory | undefined): SwitchOfferOption[] => {
    if (productCategory) {
        let monthOptions: SwitchOfferOption[] = []

        productCategory.products.forEach((product) => {
            if (product.subCategory && product.subCategory.trim().length > 0) {
                const subcategory = JSON.parse(product.subCategory)
                const monthOption: SwitchOfferOption = {
                    title: subcategory.name,
                    order: subcategory.position,
                }

                if (subcategory.superText && subcategory.superText.trim().length > 0) {
                    monthOption.offerText = subcategory.superText
                }

                const optionExist = monthOptions.find((mo) => {
                    return mo.title === monthOption.title
                })
                if (!optionExist) {
                    monthOptions.push(monthOption)
                }
            }
        })

        monthOptions = monthOptions.sort((a, b) => {
            return Number(a.order) - Number(b.order)
        })

        return monthOptions
    }

    return []
}
