import gql from 'graphql-tag'

export default gql`
    query LoadAddressesDetails($context: String!) {
        loadAddressesDetail(context: $context) {
            language
            location {
                latitude
                longitude
                precision
            }
            country {
                name
                iso3Code
            }
            mailLines
            address {
                country
                locality
                street
                postcode
                building
                buildingNumber
                buildingNumberAddition
            }
        }
    }
`
