const colors = {
    black: '#000000',
    color1: '#222222',
    color2: 'rgba(163, 137, 191, 0.8)',
    color3: '#CFA22E',
    darkGray: '#8a8f8d',
    basketGray: '#909090',
    darkerGray: '#737373',
    green: '#a389bf',
    lightGreen: 'rgba(163, 137, 191, 0.6)',
    header: '#FFFFFF',
    lightGray: '#F4F4F4',
    midGray: '#C4C4C4',
    warning: '#EB5757',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    white: '#FFFFFF',
    basketInfoIcon: 'rgba(34, 34, 34, 0.54)',
    buttonPrimaryColor: '#9bc4d0',
    buttonSecondaryColor: '#C8D428',
    buttonThirdColor: '#8A8F8D',
}

export default colors
