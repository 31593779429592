import { Grid, GridProps, Typography } from '@material-ui/core'
import * as React from 'react'
import PriceDescription, { PriceDescriptionProps } from '../PriceDescription/PriceDescription'

export interface ProductTableRowProps extends Omit<GridProps, 'children'> {
    description: string
    value: PriceDescriptionProps
    iconStyle?: React.CSSProperties
    isBasket?: boolean
    category: string
}

const ProductTableRow = (props: ProductTableRowProps): JSX.Element => {
    const { description, iconStyle, value, isBasket, ...rest } = props
    return (
        <Grid item container className={'ProductTableRow'} {...rest}>
            <Grid item xs={isBasket ? 6 : 12} sm>
                <Typography className={'ProductTableRowText'} dangerouslySetInnerHTML={{ __html: description }} />
            </Grid>
            <Grid item xs={isBasket ? 6 : 12} sm>
                <PriceDescription {...value} iconStyle={iconStyle} isBasket={isBasket} />
            </Grid>
        </Grid>
    )
}

export default ProductTableRow
