import { Grid } from '@material-ui/core'
import OfferOption from 'components/OfferOption'

export interface SwitchMonthProps {
    monthOptions: SwitchOfferOption[]
    onSelect: (id: number) => void
    selected: number
}
export interface SwitchOfferOption {
    offerText?: string
    title: string
    order: number
}

const SwitchMonth = (props: SwitchMonthProps): JSX.Element => {
    const { monthOptions, onSelect, selected } = props
    return (
        <Grid item container direction={'row'} className={'SwitchMonthGrid'}>
            {monthOptions.map(
                (monthOption: SwitchOfferOption, index: number): JSX.Element => (
                    <Grid item xs sm={'auto'} key={index + monthOption.title}>
                        <OfferOption
                            id={index}
                            offerText={monthOption.offerText}
                            onSelect={onSelect}
                            selected={selected === index}
                            title={monthOption.title}
                        />
                    </Grid>
                ),
            )}
        </Grid>
    )
}

export default SwitchMonth
