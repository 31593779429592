import { Customize } from 'utils/customize'
import { Product, ProductCategory, ProductType } from '../../graphql/types'
import { Page, SelectedProduct, SelectedProductCategory } from '../../store/GeneralState/GeneralState.reducer'

export const updatePagesList = (
    availableProducts: ProductCategory[],
    selectedProductCategories: SelectedProductCategory[],
    initialPageList: Page[],
    B2B: boolean,
    customize?: Customize,
): Page[] => {
    const pagesToReturn = [...initialPageList]
    const configurationPart: Page[] = []
    let displayTelephoneProduct = false
    selectedProductCategories.forEach((PC: SelectedProductCategory) => {
        configurationPart.push({ path: '/Produktauswahl/' + PC.id })
        const selectedProductCategoryFromData = availableProducts.find(
            (productCategory: ProductCategory) => productCategory.id === PC.id,
        )
        if (PC.selectedProduct && selectedProductCategoryFromData) {
            const selectedProductFromData = selectedProductCategoryFromData.products.find(
                (product: Product): boolean => {
                    if (PC.selectedProduct) {
                        return product.id === PC.selectedProduct.id
                    }
                    return false
                },
            )
            if (selectedProductFromData && selectedProductFromData.productTypes.length > 0) {
                selectedProductFromData.productTypes.forEach((productType, index) => {
                    if (PC.selectedProduct && productType.optional) {
                        configurationPart.push({
                            path: '/Produkttypauswahl/' + PC.selectedProduct.id + '/' + productType.identifier,
                        })
                    }
                    if (
                        PC.selectedProduct &&
                        (!productType.optional ||
                            PC.selectedProduct.productTypes.findIndex((v) => v.id === productType.id) !== -1)
                    ) {
                        let hasHardwareOptions = false
                        let hasServiceOptions = false
                        for (const category of productType.category) {
                            if (category.type === 'service') {
                                hasServiceOptions = true
                            } else if (
                                category.type === 'hardware' &&
                                hardwareIsDisplayed(PC.selectedProduct, productType, customize)
                            ) {
                                hasHardwareOptions = true
                            }
                        }

                        if (index === 0) {
                            if (selectedProductFromData.productTypes.length > 1) {
                                if (productType.category.length >= 0) {
                                    if (hasServiceOptions) {
                                        configurationPart.push({
                                            path:
                                                '/Produktoptionen/service/' +
                                                PC.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                    if (hasHardwareOptions) {
                                        configurationPart.push({
                                            path:
                                                '/Produktoptionen/hardware/' +
                                                PC.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                }
                            } else {
                                if (productType.category.length > 0) {
                                    if (hasServiceOptions) {
                                        configurationPart.push({
                                            path:
                                                '/Produktoptionen/service/' +
                                                PC.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                    if (hasHardwareOptions) {
                                        configurationPart.push({
                                            path:
                                                '/Produktoptionen/hardware/' +
                                                PC.selectedProduct.id +
                                                '/' +
                                                productType.identifier,
                                        })
                                    }
                                }
                            }
                        } else {
                            if (productType.category.length > 0) {
                                if (hasServiceOptions) {
                                    configurationPart.push({
                                        path:
                                            '/Produktoptionen/service/' +
                                            PC.selectedProduct.id +
                                            '/' +
                                            productType.identifier,
                                    })
                                }
                                if (hasHardwareOptions) {
                                    configurationPart.push({
                                        path:
                                            '/Produktoptionen/hardware/' +
                                            PC.selectedProduct.id +
                                            '/' +
                                            productType.identifier,
                                    })
                                }
                            }
                        }
                    }

                    if (
                        PC.selectedProduct &&
                        productType.identifier === 'telephone' &&
                        PC.selectedProduct.productTypes.findIndex((v) => v.id === productType.id) !== -1
                    ) {
                        displayTelephoneProduct = true
                    }
                })
            }
        }
    })
    pagesToReturn.splice(2, 0, ...configurationPart)

    //Add landline options if the customer select a product which is for telephone
    if (displayTelephoneProduct) {
        const contactPathIndex = pagesToReturn.findIndex((p) => p.path === '/Kontaktdaten')
        const telephonPathIndex = pagesToReturn.findIndex((p) => p.path === '/FestnetzVertragsOptionen')
        if (telephonPathIndex === -1)
            pagesToReturn.splice(contactPathIndex + 1, 0, { path: '/FestnetzVertragsOptionen' })
        if (telephonPathIndex !== -1) pagesToReturn.splice(telephonPathIndex, 1)
    }

    if (customize && customize.voucherConfiguration.enableManual) {
        const voucherPathIndex = pagesToReturn.findIndex((p) => p.path === '/Gutscheineingabe')
        const instalationPathIndex = pagesToReturn.findIndex((p) => p.path === '/Installationsdetails')
        if (voucherPathIndex === -1) {
            pagesToReturn.splice(instalationPathIndex + 1, 0, { path: '/Gutscheineingabe' })
        }
    }

    const vzfIndex = pagesToReturn.findIndex((p) => p.path === '/VZF')
    if (B2B && vzfIndex !== -1) {
        pagesToReturn.splice(vzfIndex, 1)
    } else if (!B2B && vzfIndex === -1) {
        const contactPathIndex = pagesToReturn.findIndex((p) => p.path === '/Kontaktdaten')
        pagesToReturn.splice(contactPathIndex - 1, 0, { path: '/VZF' })
    }

    const vzfLandingpageIndex = pagesToReturn.findIndex((p) => p.path === '/VZFLandingpage')
    if (B2B && vzfLandingpageIndex !== -1) {
        pagesToReturn.splice(vzfLandingpageIndex, 1)
    } else if (!B2B && vzfLandingpageIndex === -1) {
        const contactPathIndex = pagesToReturn.findIndex((p) => p.path === '/Kontaktdaten')
        pagesToReturn.splice(contactPathIndex - 1, 0, { path: '/VZFLandingpage' })
    }

    return pagesToReturn
}

export const checkIfWeShouldAddPortabilityPage = (
    configurationState: Map<string, string | string[]>,
    pageList: Page[],
    customize?: Customize,
): Page[] => {
    const returndPages: Page[] = [...pageList]
    if (customize) {
        const telephonPathIndex = pageList.findIndex((p) => p.path === '/FestnetzVertragsOptionen')
        const portabityPathIndex = pageList.findIndex((p) => p.path === '/Rufnummernmitnahme')
        const value = configurationState.get(customize.portabilityConfiguration.showPortability.identifier) as string
        if (portabityPathIndex === -1 && value === customize.portabilityConfiguration.showPortability.wishValue)
            returndPages.splice(telephonPathIndex + 1, 0, { path: '/Rufnummernmitnahme' })
        if (portabityPathIndex !== -1 && value !== customize.portabilityConfiguration.showPortability.wishValue)
            returndPages.splice(portabityPathIndex, 1)
    }
    return returndPages
}

const hardwareIsDisplayed = (
    selectedProduct: SelectedProduct,
    productType: ProductType,
    customize?: Customize,
): boolean => {
    if (customize) {
        for (const hardwareSelection of customize?.globalConfiguration.enableHardwareSelection) {
            if (hardwareSelection === productType.identifier) {
                const spt = selectedProduct.productTypes.find((v) => v.id === productType.id)
                return spt != undefined && spt.optionCategories.length > 0
            }
        }
    }
    return true
}
