import { AccountType, InstallationDetailsState, PdfContent, PdfDataInput, PdfEntry } from 'graphql/types'
import { TFunction } from 'i18next'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import { GeneralState } from 'store/GeneralState/GeneralState.reducer'
import { PortabilityState } from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import { Customize } from './customize'
import { generateVZFDataForText } from './generateVZFDataForText'

export const stateToPDFdata = (
    appState: AppState,
    b2b: boolean,
    t: TFunction,
    customizeJsData: Customize | undefined,
): PdfDataInput => {
    const vzfData = generateVZFDataForText(appState, t, b2b, false, customizeJsData)

    const pdfEntries: PdfEntry[] = []

    const installationData = getInstallationDataPdf(appState.generalState.installationDetails, t, customizeJsData)
    pdfEntries.push(installationData)

    const telephoneData = getTelephoneContractDataPdf(appState.generalState, appState.portabilityState, t)
    pdfEntries.push(telephoneData)

    const bankData = getBankDataPdf(appState.generalState, appState.bankDetails, t, customizeJsData, b2b)
    pdfEntries.push(bankData)

    const desiredDateData = getDesiredDataPdf(appState.generalState, t)
    pdfEntries.push(desiredDateData)

    return {
        PDFSummaryEntries: pdfEntries,
        costs: vzfData.costs,
    }
}

const getInstallationDataPdf = (
    installationData: InstallationDetailsState,
    t: TFunction,
    customizeJsData: Customize | undefined,
): PdfEntry => {
    const content: PdfContent[] = []

    if (customizeJsData) {
        if (customizeJsData.installationDetailsConfiguration.junctionBox) {
            content.push({
                Key: parseHTML(t('orderOverviewStrings.installation.junctionBox')),
                Value: parseHTML(t('installationDetailsStrings.junctionBox.' + installationData.junctionBox)),
                Description: [],
            })
        }

        if (customizeJsData.installationDetailsConfiguration.houseConnection) {
            content.push({
                Key: parseHTML(t('orderOverviewStrings.installation.houseConnection')),
                Value: parseHTML(t('installationDetailsStrings.houseConnection.' + installationData.houseConnection)),
                Description: [],
            })
            if (installationData.houseConnection === 'OptionTitle1') {
                content.push({
                    Key: parseHTML(t('installationDetailsStrings.houseConnectionInputLabel')),
                    Value: parseHTML(installationData.houseConnectionInput),
                    Description: [],
                })
            }
        }
        if (customizeJsData.installationDetailsConfiguration.opticFiber) {
            content.push({
                Key: parseHTML(t('orderOverviewStrings.installation.opticFiber')),
                Value: parseHTML(t('installationDetailsStrings.opticFiber.' + installationData.opticFiber)),
                Description: [],
            })
            if (installationData.houseConnection === 'OptionTitle1') {
                content.push({
                    Key: parseHTML(t('installationDetailsStrings.opticFiberInputLabel')),
                    Value: parseHTML(installationData.opticFiberInput),
                    Description: [],
                })
            }
        }
    }

    return { Headline: t('orderOverviewStrings.installation.categoryTitle'), Content: content }
}

const getTelephoneContractDataPdf = (
    generalState: GeneralState,
    portabilityState: PortabilityState,
    t: TFunction,
): PdfEntry => {
    const content: PdfContent[] = []

    if (generalState.customizeJsData) {
        if (generalState.customizeJsData.telephoneOptions.portabilityVisible) {
            const portabilityValue = generalState.configuration.get(
                generalState.customizeJsData.portabilityConfiguration.showPortability.identifier,
            )
            if (portabilityValue === generalState.customizeJsData.portabilityConfiguration.showPortability.wishValue) {
                content.push({
                    Key: t('orderOverviewStrings.telephoneContractData.portability.porting'),
                    Value:
                        portabilityState.phoneOptions[0].areaCode === '' &&
                        portabilityState.phoneOptions[0].number === ''
                            ? t('orderOverviewStrings.telephoneContractData.portability.no')
                            : t('orderOverviewStrings.telephoneContractData.portability.yes'),
                    Description: [],
                })
                content.push({
                    Key: t(
                        'orderOverviewStrings.telephoneContractData.' +
                            generalState.customizeJsData.portabilityConfiguration.showPortability.identifier,
                    ),
                    Value: portabilityState.phoneOptions[0].areaCode + portabilityState.phoneOptions[0].number,
                    Description: [],
                })

                content.push({
                    Key: t('orderOverviewStrings.telephoneContractData.portability.provider'),
                    Value: portabilityState.selectedProvider,
                    Description: [],
                })
                if (portabilityState.selectedRadios.includes('terminatedContractNo')) {
                    content.push({
                        Key: t('orderOverviewStrings.telephoneContractData.portability.terminatedContract'),
                        Value: portabilityState.endOfContract,
                        Description: [],
                    })
                } else {
                    content.push({
                        Key: t('orderOverviewStrings.telephoneContractData.portability.terminatedContract'),
                        Value: t('orderOverviewStrings.telephoneContractData.no'),
                        Description: [],
                    })
                }
            }
        }
        generalState.configuration.forEach((value, key) => {
            if (generalState.customizeJsData) {
                let showEntry = false
                for (const configuration of generalState.customizeJsData.telephoneOptions.telephoneConfiguration) {
                    if (configuration.type === 'conditional' && configuration.render) {
                        const conditionValue = generalState.configuration.get(configuration.condition?.identifier ?? '')
                        if (conditionValue !== configuration.condition?.value) continue
                        for (const subConfiguration of configuration.render) {
                            if (subConfiguration.identifier === key) {
                                showEntry = true
                            }
                        }
                        if (showEntry) break
                    } else if (configuration.identifier === key) {
                        showEntry = true
                        break
                    }
                }
                if (
                    showEntry &&
                    key !== 'invoiceSend' &&
                    key !== 'informationServices' &&
                    key !== generalState.customizeJsData.portabilityConfiguration.showPortability.identifier &&
                    generalState.customizeJsData.orderOverview.legalTerms.checkboxes.some(
                        (checkbox) => checkbox.value === key,
                    ) === false &&
                    ((generalState.customizeJsData.orderOverview.newsletters &&
                        generalState.customizeJsData.orderOverview.newsletters.checkboxes.includes(key) === false) ||
                        generalState.customizeJsData.orderOverview.newsletters === undefined) &&
                    ((generalState.customizeJsData.orderOverview.vzf &&
                        generalState.customizeJsData.orderOverview.vzf.checkboxes.includes(key) === false) ||
                        generalState.customizeJsData.orderOverview.vzf === undefined)
                ) {
                    content.push({
                        Key: t('orderOverviewStrings.telephoneContractData.' + key),
                        Value:
                            typeof value === 'string'
                                ? t('orderOverviewStrings.telephoneContractData.' + value)
                                : value
                                      .map((value) => t('orderOverviewStrings.telephoneContractData.' + value))
                                      .join(', '),
                        Description: [],
                    })
                }
            }
        })
        if (generalState.configuration.get('entryInPhoneBook')) {
            const informationServicesConfig = generalState.configuration.get('informationServices') as string[]
            const inverseSearchKey =
                informationServicesConfig && informationServicesConfig.includes('inverseSearch') ? 'yes' : 'no'

            content.push({
                Key: t('orderOverviewStrings.telephoneContractData.' + 'inverseSearchTitle'),
                Value: t('orderOverviewStrings.telephoneContractData.' + inverseSearchKey),
                Description: [],
            })
        }
    }

    return { Headline: parseHTML(t('orderOverviewStrings.telephoneContractData.categoryTitle')), Content: content }
}

const getBankDataPdf = (
    generalState: GeneralState,
    bankData: BankDetailsState,
    t: TFunction,
    customize: Customize | undefined,
    b2b: boolean,
): PdfEntry => {
    const content: PdfContent[] = []

    if (bankData.accountType === AccountType.IBAN) {
        content.push({
            Key: t('orderOverviewStrings.bankDetails.accountType'),
            Value: t('orderOverviewStrings.bankDetails.selfpay'),
            Description: [],
        })

        let iban = bankData.iban.replace(/ /g, '').trim()
        iban = iban.substring(0, 3) + 'XXXXXXXXXXXXXXXX' + iban.substring(iban.length - 3, iban.length)
        content.push({
            Key: t('orderOverviewStrings.bankDetails.iban'),
            Value: iban,
            Description: [],
        })
        if (customize && customize.bankDetails.displayDayOfTransfer) {
            content.push({
                Key: t('bankDetailsStrings.transferMonthlyTitle'),
                Value:
                    bankData.dayOfTransfer === '15'
                        ? t('bankDetailsStrings.transfer15')
                        : t('bankDetailsStrings.transfer29'),
                Description: [],
            })
        }
    } else {
        content.push({
            Key: t('orderOverviewStrings.bankDetails.accountType'),
            Value: t('orderOverviewStrings.bankDetails.transfer'),
            Description: [],
        })
    }
    if (generalState.configuration.get('invoiceSend')) {
        const invoiceSendValue = generalState.configuration.get('invoiceSend') as string

        content.push({
            Key: t('orderOverviewStrings.telephoneContractData.' + 'invoiceSend'),
            Value: t(
                'orderOverviewStrings.telephoneContractData.' + b2b ? invoiceSendValue + '_B2B' : invoiceSendValue,
            ),
            Description: [],
        })
    }

    return { Headline: t('orderOverviewStrings.bankDetails.categoryTitle'), Content: content }
}

const getDesiredDataPdf = (generalState: GeneralState, t: TFunction): PdfEntry => {
    const content: PdfContent[] = []

    if (generalState.earliestDatePossible || generalState.desiredDate === null) {
        content.push({
            Key: t('orderOverviewStrings.desiredDate.connection'),
            Value: t('orderOverviewStrings.desiredDate.earliestDatePossible'),
            Description: [],
        })
    } else {
        content.push({
            Key: t('orderOverviewStrings.desiredDate.connection'),
            Value: generalState.desiredDate.toLocaleDateString(),
            Description: [],
        })
    }

    return { Headline: t('orderOverviewStrings.desiredDate.categoryTitle'), Content: content }
}

const parseHTML = (html: string): string => {
    const div = document.createElement('div')
    div.innerHTML = html.replace(/&#173;/g, '')
    return div.innerText
}

export const getMultiplicator = (n: number | undefined): number => {
    let multiplicator = 1
    if (n && n > 1) {
        multiplicator = n
    }
    return multiplicator
}
