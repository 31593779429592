import { OptionItemContent } from 'components/OptionItem/OptionItem'
import { Dispatch, useCallback, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { GeneralState, InstallationDetailsState, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import {
    ConfigurationHouseConnection,
    ConfigurationJunctionBox,
    ConfigurationOpticFiber,
    Customize,
    HouseConnectionOption,
    JunctionBoxOption,
    OpticFiberOption,
} from 'utils/customize'

interface InstallationDetailsReducerReturn {
    b2b: boolean
    convertJunctionBoxOptionToOptionItemContent: (junctionBox: ConfigurationJunctionBox) => OptionItemContent[]
    convertHouseConnectionOptionToOptionItemContent: (
        houseConnection: ConfigurationHouseConnection,
    ) => OptionItemContent[]
    convertOpticFiberOptionToOptionItemContent: (opticFiber: ConfigurationOpticFiber) => OptionItemContent[]
    convertYoutubeLinkToID: (link: string) => string
    currentView: ViewType
    customizeJsData: Customize | undefined
    disabledSubmit: boolean
    installationDetails: InstallationDetailsState
    submitButtonText: string | undefined
    onChange: (field: InstallationDetailsFields, value: string | number) => void
    setInstallationDetailsState: (payload: InstallationDetailsState) => void
    generalState: GeneralState
}
export enum InstallationDetailsFields {
    FLOOR_INFO = 'floorInformation',
    FLAT_POSITION = 'flatPosition',
    FLAT_NUMBER = 'flatNumber',
    JUNCTION_BOX = 'junctionBox',
    HOUSE_CONNECTION = 'houseConnection',
    HOUSE_CONNECTION_INPUT = 'houseConnectionInput',
    OPTIC_FIBER_INPUT = 'opticFiberInput',
    OPTIC_FIBER = 'opticFiber',
    SERVICE = 'installationService',
}
export const useInstallationDetailsReducer = (): InstallationDetailsReducerReturn => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    const { t } = useTranslation()
    const { B2B } = useURLParams()

    const { generalState, customizeJsData, installationDetails } = useSelector((appState: AppState) => {
        return {
            generalState: appState.generalState,
            customizeJsData: appState.generalState.customizeJsData,
            installationDetails: appState.generalState.installationDetails,
        }
    })
    const currentView = generalState.currentView

    let submitButtonText = undefined
    if (customizeJsData && customizeJsData.voucherConfiguration.enableManual) {
        submitButtonText = t(currentView + 'ButtonText2')
    }

    const setInstallationDetailsState = useCallback(
        (payload: InstallationDetailsState) => {
            dispatch({ type: GeneralStateAction.SET_INSTALLATION_DETAILS, payload })
        },
        [dispatch],
    )

    const onChange = (field: InstallationDetailsFields, value: string | number): void => {
        const newInstallationDetailsState = { ...installationDetails }
        switch (field) {
            case InstallationDetailsFields.FLAT_NUMBER:
                newInstallationDetailsState.flatNumber = String(value)
                break
            case InstallationDetailsFields.FLAT_POSITION:
                newInstallationDetailsState.flatPosition = String(value)
                break
            case InstallationDetailsFields.FLOOR_INFO:
                newInstallationDetailsState.floorInformation = String(value)
                break
            case InstallationDetailsFields.JUNCTION_BOX:
                newInstallationDetailsState.junctionBox = String(value)
                break
            case InstallationDetailsFields.HOUSE_CONNECTION:
                newInstallationDetailsState.houseConnection = String(value)
                break
            case InstallationDetailsFields.HOUSE_CONNECTION_INPUT:
                newInstallationDetailsState.houseConnectionInput = String(value)
                break
            case InstallationDetailsFields.OPTIC_FIBER:
                newInstallationDetailsState.opticFiber = String(value)
                break
            case InstallationDetailsFields.OPTIC_FIBER_INPUT:
                newInstallationDetailsState.opticFiberInput = String(value)
                break
            case InstallationDetailsFields.SERVICE:
                if (newInstallationDetailsState.installationService === value) {
                    newInstallationDetailsState.installationService = 0
                } else {
                    newInstallationDetailsState.installationService = Number(value)
                }
                break
        }
        setInstallationDetailsState(newInstallationDetailsState)
    }

    const disabledSubmit = useMemo((): boolean => {
        if (customizeJsData) {
            if (
                (customizeJsData.installationDetailsConfiguration.appartmentData?.flatNumberRequired === true &&
                    installationDetails.flatNumber === '') ||
                (customizeJsData.installationDetailsConfiguration.appartmentData?.flatPositionRequired === true &&
                    installationDetails.flatPosition === '') ||
                (customizeJsData.installationDetailsConfiguration.appartmentData?.floorInformationRequired === true &&
                    installationDetails.floorInformation === '') ||
                (customizeJsData?.installationDetailsConfiguration.appartmentData?.floorInformationRequiredIf ===
                    installationDetails.houseConnection &&
                    installationDetails.floorInformation === '') ||
                (customizeJsData?.installationDetailsConfiguration.appartmentData?.flatPositionRequiredIf ===
                    installationDetails.houseConnection &&
                    installationDetails.flatPosition === '') ||
                (customizeJsData?.installationDetailsConfiguration.appartmentData?.flatNumberRequiredIf ===
                    installationDetails.houseConnection &&
                    installationDetails.flatNumber === '') ||
                (customizeJsData.installationDetailsConfiguration.junctionBox?.required === true &&
                    installationDetails.junctionBox === '')
            ) {
                return true
            } else {
                return false
            }
        } else return true
    }, [installationDetails])

    const convertJunctionBoxOptionToOptionItemContent = (
        junctionBox: ConfigurationJunctionBox,
    ): OptionItemContent[] => {
        return junctionBox.options.map((option: JunctionBoxOption) => {
            return {
                id: option.title,
                selectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
                info: t('installationDetailsStrings.junctionBox.' + option.information) ?? '',
                title: t('installationDetailsStrings.junctionBox.' + option.title),
                subtitle: t('installationDetailsStrings.junctionBox.' + option.subtitle),
                datasheet: option.datasheet,
                unselectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
            }
        })
    }

    const convertHouseConnectionOptionToOptionItemContent = (
        houseConnection: ConfigurationHouseConnection,
    ): OptionItemContent[] => {
        return houseConnection.options.map((option: HouseConnectionOption) => {
            return {
                id: option.title,
                selectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
                info: t('installationDetailsStrings.houseConnection.' + option.information) ?? '',
                title: t('installationDetailsStrings.houseConnection.' + option.title),
                unselectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
            }
        })
    }

    const convertOpticFiberOptionToOptionItemContent = (opticFiber: ConfigurationOpticFiber): OptionItemContent[] => {
        return opticFiber.options.map((option: OpticFiberOption) => {
            return {
                id: option.title,
                selectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
                info: t('installationDetailsStrings.opticFiber.' + option.information) ?? '',
                title: t('installationDetailsStrings.opticFiber.' + option.title),
                unselectedImage:
                    option.badgeIdentifier !== undefined && option.badgeIdentifier !== ''
                        ? option.badgeIdentifier + '.png'
                        : '',
            }
        })
    }

    const convertYoutubeLinkToID = (link: string): string => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        const match = link.match(regExp)
        if (match) {
            return match[7]
        }
        return ''
    }

    useLayoutEffect(() => {
        const newInstallationDetailsState = { ...installationDetails }
        if (
            customizeJsData &&
            customizeJsData.installationDetailsConfiguration &&
            customizeJsData.installationDetailsConfiguration.junctionBox &&
            customizeJsData.installationDetailsConfiguration.junctionBox.preselected
        ) {
            const preselectedJunctionBox =
                customizeJsData.installationDetailsConfiguration.junctionBox.options[
                    customizeJsData.installationDetailsConfiguration.junctionBox.preselected
                ]
            if (newInstallationDetailsState.junctionBox === '') {
                newInstallationDetailsState.junctionBox = preselectedJunctionBox.title
            }
        }
        if (
            customizeJsData &&
            customizeJsData.installationDetailsConfiguration &&
            customizeJsData.installationDetailsConfiguration.houseConnection
        ) {
            const preselectedHouseConnection =
                customizeJsData.installationDetailsConfiguration.houseConnection.options[
                    customizeJsData.installationDetailsConfiguration.houseConnection.preselected
                ]
            if (newInstallationDetailsState.houseConnection === '') {
                newInstallationDetailsState.houseConnection = preselectedHouseConnection.title
            }
        }
        if (
            customizeJsData &&
            customizeJsData.installationDetailsConfiguration &&
            customizeJsData.installationDetailsConfiguration.opticFiber
        ) {
            const preselectedOpticFiber =
                customizeJsData.installationDetailsConfiguration.opticFiber.options[
                    customizeJsData.installationDetailsConfiguration.opticFiber.preselected
                ]
            if (newInstallationDetailsState.opticFiber === '') {
                newInstallationDetailsState.opticFiber = preselectedOpticFiber.title
            }
        }
        setInstallationDetailsState(newInstallationDetailsState)
    }, [])

    return {
        b2b: B2B,
        convertJunctionBoxOptionToOptionItemContent,
        convertHouseConnectionOptionToOptionItemContent,
        convertOpticFiberOptionToOptionItemContent,
        convertYoutubeLinkToID,
        currentView,
        customizeJsData,
        disabledSubmit,
        installationDetails,
        onChange,
        setInstallationDetailsState,
        submitButtonText,
        generalState,
    }
}
