import { BillMode, VoucherCurrency, VzfpdfData } from 'graphql/types'
import { TFunction } from 'i18next'
import { AppState } from 'store/store'
import { formatCurrency } from 'utils/numberFormat'
import { getMultiplicator } from 'utils/stateToPDFData'
import { basketCalculation } from 'utils/testable/basketCalculation'
import { Customize } from './customize'

export const generateVZFDataForText = (
    state: AppState,
    t: TFunction,
    B2B: boolean,
    isForVZF: boolean,
    customizeJsData: Customize | undefined,
): VzfpdfData => {
    const pickIntroText = () => {
        const translated: string[] = []
        if (customizeJsData) {
            if (customizeJsData.vzfConfiguration.introText.length > 0) {
                if (customizeJsData.vzfConfiguration.introText.length > 1) {
                    customizeJsData.vzfConfiguration.introText.forEach((text) => {
                        translated.push(t(`vzf.${text}`))
                    })
                } else {
                    translated.push(t(`vzf.${customizeJsData.vzfConfiguration.introText[0]}`))
                }
            }
        }
        return translated
    }
    const pickFooterText = () => {
        const translated: { name: string; page: number }[] = []
        if (customizeJsData) {
            if (customizeJsData.vzfConfiguration.footNoteText.length > 0) {
                if (customizeJsData.vzfConfiguration.footNoteText.length > 0) {
                    customizeJsData.vzfConfiguration.footNoteText.forEach((text) => {
                        translated.push({ name: t(`vzf.${text.name}`), page: text.page })
                    })
                }
            }
        }
        return translated
    }
    const vzfData: VzfpdfData = {
        products: [],
        text: {
            intro: pickIntroText(),
            footer: pickFooterText(),
        },
        costs: {
            monthlyCost: '',
            oneTimeCost: '',
            monthlyItems: [],
            oneTimeItems: [],
            categoryCost: [],
            totalDiscounts: {
                sum: '',
                discounts: [],
            },
        },
        functionalFeaturesDisability: t('vzf.vzfDoesntApply'),
        optionalCosts: [],
        services: [],
        devices: [],
        costHint1: t('vzf.vzfCostHint1'),
        costHint2: t('vzf.vzfCostHint2'),
        costHint3: t('vzf.vzfCostHint3'),
        vzfID: state.generalState.vzfID,
        contractTerms: [],
    }
    const calculationBasket = basketCalculation(
        state.availabilityCheck,
        state.bankDetails,
        state.generalState,
        B2B,
        isForVZF,
    )
    const monthlyCost = calculationBasket.costs?.monthlyCost ?? 0
    vzfData.costs.monthlyCost = formatCurrency(monthlyCost)
    vzfData.costs.oneTimeCost = formatCurrency(calculationBasket.costs?.oneTimeCost ?? 0)

    vzfData.costs.totalDiscounts.sum = formatCurrency(calculationBasket.costs?.totalDiscounts.sum ?? 0)

    calculationBasket.costs &&
        calculationBasket.costs.totalDiscounts.discounts.forEach((discount) => {
            vzfData.costs.totalDiscounts.discounts.push({
                name: discount.name,
                oldPrice: formatCurrency(discount.oldPrice),
                newPrice: formatCurrency(discount.newPrice),
            })
        })

    state.generalState.selectedProductCategories.forEach((selectedProductCategory) => {
        const selectedProduct = selectedProductCategory.selectedProduct

        // Get selected product category data from available categories
        const productCategory = state.generalState.availableProductCategories.find(
            (availableProductCategory) => availableProductCategory.id === selectedProductCategory.id,
        )
        if (!productCategory || !selectedProduct) return

        // Get selected product data from available products
        const product = productCategory.products.find((availableProduct) => availableProduct.id === selectedProduct.id)
        if (!product) return
        state.generalState.availableProductCategories.forEach((availableProductCategory) => {
            const prd = availableProductCategory.products.find((p) => p.id === product.id)
            if (prd) {
                vzfData.products.push(prd.title)
            }
        })

        if (product.minimumContractPeriod && product.minimumContractPeriod.trim().length > 0) {
            vzfData.contractTerms.push(
                t('vzf.vzfContractTermText', {
                    product: parseHTML(product.title),
                    minimumContractPeriod: product.minimumContractPeriod,
                }),
            )
        }

        selectedProduct.productTypes.forEach((selectedProductType) => {
            const informationList: string[] = []
            const productType = product.productTypes.find(
                (availableProductType) => availableProductType.id === selectedProductType.id,
            )
            if (!productType) return

            if (product.footnoteText.trim().length > 0) informationList.push(product.footnoteText)
            if (productType.footnoteText.trim().length > 0) informationList.push(productType.footnoteText)

            // Get all selected option category data from available option categories
            selectedProductType.optionCategories.forEach((selectedOptionCategory) => {
                const optionCategory = productType.category.find(
                    (availableOptionCategory) => availableOptionCategory.id === selectedOptionCategory.id,
                )
                if (!optionCategory) return

                // Get all selected option data from available options
                selectedOptionCategory.selectedOptions.forEach((selectedOption) => {
                    const option = optionCategory.options.find(
                        (availableOption) => availableOption.id === selectedOption,
                    )
                    if (option) {
                        if (option.footnoteText.trim().length > 0) {
                            if (option.isHardware) {
                                vzfData.devices.push(option.footnoteText)
                            } else {
                                informationList.push(option.footnoteText)
                            }
                        }
                    }
                    if (option && option.minimumContractPeriod && option.minimumContractPeriod.trim().length > 0) {
                        vzfData.contractTerms.push(
                            t('vzf.vzfContractTermText', {
                                product: option.title,
                                minimumContractPeriod: option.minimumContractPeriod,
                            }),
                        )
                    }
                })
            })

            const serviceName = selectedProduct.productTypes.length > 1 ? productType.title : parseHTML(product.title)
            vzfData.services.push({
                title: serviceName,
                type: productType.identifier,
                information: informationList,
            })
            if (vzfData.products.indexOf(serviceName) === -1) {
                vzfData.products.push(serviceName)
            }

            if (productType.identifier === 'internet') {
                vzfData.internet = {
                    speedTerm: t('vzf.vzfConnectionIssueInfo'),
                    upload: {
                        max: productType.upload,
                        normal: productType.uploadStandard,
                        min: productType.uploadMinimal,
                    },
                    download: {
                        max: productType.download,
                        normal: productType.downloadStandard,
                        min: productType.downloadMinimal,
                    },
                }
            }
        })
    })

    for (const productCategory of calculationBasket.products.productCategories) {
        for (const product of productCategory.products) {
            const category = getVZFCostCategory(product.category, t)
            let index = vzfData.costs.categoryCost.findIndex((e) => e.title === category.title)
            if (index === -1) {
                vzfData.costs.categoryCost.push({
                    sort: category.sort,
                    title: category.title,
                    entries: [],
                })
                const lenght = vzfData.costs.categoryCost.length
                index = lenght > 0 ? lenght - 1 : 0
            }

            let price = ''

            if (product.value.billMode === BillMode.VOUCHER) {
                let billMode = BillMode.ONE_TIME
                if (product.value.voucherData?.month !== undefined && product.value.voucherData?.month > 1) {
                    billMode = BillMode.RECURRING_MONTHLY
                }

                vzfData.costs.categoryCost[index].entries.push({
                    title: parseHTML(product.description),
                    value:
                        product.value.voucherData?.currency === VoucherCurrency.EURO
                            ? '-' + formatCurrency(product.value.newPrice)
                            : '-' + product.value.newPrice + '%',
                    billMode: billMode,
                })
            } else {
                const multiplicator = getMultiplicator(product.value.multiple)
                if (product.value.oldPrice !== undefined) {
                    price = formatCurrency(product.value.oldPrice * multiplicator)
                } else {
                    price = formatCurrency(product.value.newPrice * multiplicator)
                }

                vzfData.costs.categoryCost[index].entries.push({
                    title: parseHTML(product.description),
                    value: product.value.oldPrice === undefined ? price : formatCurrency(product.value.newPrice),
                    billMode: product.value.billMode,
                })
            }
        }
    }

    vzfData.costs.categoryCost.sort((a, b) => {
        if (a.sort < b.sort) {
            return -1
        }
        if (a.sort > b.sort) {
            return 1
        }

        return 0
    })

    let productTitles = ''
    let feeTotal = 0
    if (
        customizeJsData &&
        customizeJsData.vzfConfiguration.optionalFees &&
        customizeJsData.vzfConfiguration.optionalFees.length > 0
    ) {
        customizeJsData.vzfConfiguration.optionalFees.forEach((fee, i) => {
            feeTotal = feeTotal + fee.value
            productTitles = productTitles + `${i !== 0 ? ',' : ''} ${t('vzf.vzfOptionalCosts.' + fee.name)}`
        })
    }

    if (customizeJsData && customizeJsData.vzfConfiguration.optionalFees) {
        vzfData.optionalCosts.push({
            title: `${t('vzf.vzfTotalFromStartOfContract')} ${productTitles}`,
            value: formatCurrency(monthlyCost + feeTotal),
        })
    }

    calculationBasket.costs?.monthlyIncrements.forEach((increment) => {
        vzfData.costs.monthlyItems.push({
            title: increment.title,
            value: formatCurrency(increment.value.newPrice),
        })
        if (
            customizeJsData &&
            customizeJsData.vzfConfiguration.optionalFees &&
            customizeJsData.vzfConfiguration.optionalFees.length > 0
        ) {
            vzfData.optionalCosts.push({
                title: `${t('vzf.vzfTotalFromMonth', { month: increment.title })} ${productTitles}`,
                value: formatCurrency(increment.value.newPrice + feeTotal),
            })
        }
    })

    return vzfData
}

const parseHTML = (html: string): string => {
    const div = document.createElement('div')
    div.innerHTML = html.replace(/&#173;/g, '')
    return div.innerText
}

interface VZFPriceCategory {
    title: string
    sort: number
}

const getVZFCostCategory = (identifier: string, t: TFunction): VZFPriceCategory => {
    switch (identifier) {
        case 'combi':
        case 'combi-custom':
        case 'internet':
        case 'internetService':
            return { title: t('vzf.remunerationIpServiceHeadline'), sort: 0 }
            break
        case 'internetHardware':
            return { title: t('vzf.remunerationIpHardwareHeadline'), sort: 1 }
            break
        case 'television':
        case 'televisionService':
            return { title: t('vzf.remunerationTVServiceHeadline'), sort: 2 }
            break
        case 'televisionHardware':
            return { title: t('vzf.remunerationTVHardwareHeadline'), sort: 3 }
            break
        case 'telephone':
        case 'telephoneService':
            return { title: t('vzf.remunerationTelephoneServiceHeadline'), sort: 4 }
            break
        case 'telephoneHardware':
            return { title: t('vzf.remunerationTelephoneHardwareHeadline'), sort: 5 }
            break
        default:
            return { title: t('vzf.priceComponentsHeadline'), sort: 6 }
    }
}
