import numbro from 'numbro'
// @ts-expect-error: import german from numbro
import german from 'numbro/dist/languages/de-DE.min.js'

numbro.registerLanguage(german)
numbro.setLanguage('de-DE')

export const formatCurrency = (value: number): string =>
    numbro(value).formatCurrency({
        spaceSeparatedCurrency: true,
        thousandSeparated: true,
        mantissa: 2,
        trimMantissa: value < 0.01 ? true : false,
        roundingFunction: (num: number) => Math.round(num),
    })
