import { Box, CircularProgress, Typography } from '@material-ui/core'
import useOrderDataLoad from 'pages/OrderDataLoad/useOrderDataLoad'
import { RouteComponentProps } from 'react-router-dom'
import colors from 'utils/colors'

export interface OrderDataLoadProps {
    id: string
    token: string
}

const OrderDataLoad = (props: RouteComponentProps<OrderDataLoadProps>): JSX.Element => {
    const { match } = props
    useOrderDataLoad(match.params)

    return (
        <Box
            bgcolor={colors.white}
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            justifyContent={'center'}
            alignItems={'center'}
            height={'90vh'}
        >
            <CircularProgress style={{ color: colors.green }} size={'140px'} />
            <Typography style={{ marginTop: '40px' }}>LOADING</Typography>
        </Box>
    )
}

export default OrderDataLoad
