import gql from 'graphql-tag'

export default gql`
    query checkVZF($vzf: String!) {
        checkVZF(vzf: $vzf) {
            isValid
            vzf {
                selectedProductCategories
                multipleSelectOptionList {
                    key
                    value
                }
                startOfDelivery
                startOfMarketing
                zipcode
                city
                street
                houseNumber
                additionalInfo
                email
            }
            vzfID
        }
    }
`
