import { Grid } from '@material-ui/core'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    PortabilityContractHolder,
    PortabilityTelephone,
    PortabilityViewFields,
} from 'store/PortabilityState/PortabilityState.reducer'
import AddRemoveButton from './AddRemoveButton'
import RenderRadios from './RenderRadios'
import RenderTextFields from './RenderTextFields'

export interface RenderContractHolderProps {
    addOption: (field: PortabilityViewFields) => void
    isOptionEmpty: (option: PortabilityTelephone | PortabilityContractHolder) => boolean
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    options: PortabilityContractHolder[]
    removeOption: (field: PortabilityViewFields) => void
    selected: boolean
    telephoneObjectLength: number
}
const RenderContractHolder = (props: RenderContractHolderProps): JSX.Element => {
    const { addOption, isOptionEmpty, onChange, options, removeOption, telephoneObjectLength, selected } = props
    const { t } = useTranslation()
    const field = PortabilityViewFields.CONTRACT_HOLDER

    useEffect(() => {
        if (selected) {
            while (options.length > 1) {
                options.pop()
            }
            options[0].firstName = ''
            options[0].lastName = ''
        }
    }, [selected])

    return (
        <>
            <Grid container spacing={1} style={{ marginBottom: '15px', marginTop: '45px' }}>
                <RenderRadios field={field} onChange={onChange} selected={selected} />
                {!selected &&
                    options.map((option: PortabilityContractHolder, index: number) => (
                        <RenderTextFields
                            key={index + 100000000}
                            field={field}
                            firstLabel={t('portabilityDetailsStrings.' + field + '.FirstLabel')}
                            firstValue={option.firstName}
                            index={index}
                            onChange={onChange}
                            secondLabel={t('portabilityDetailsStrings.' + field + '.SecondLabel')}
                            secondValue={option.lastName}
                        />
                    ))}
            </Grid>
            {!selected && (
                <AddRemoveButton
                    addOption={addOption}
                    field={field}
                    isOptionEmpty={isOptionEmpty}
                    objectLength={telephoneObjectLength}
                    options={options}
                    removeOption={removeOption}
                />
            )}
        </>
    )
}

export default RenderContractHolder
