import { AppBar, Box, Grid, IconButton } from '@material-ui/core'
import HelpLink from 'components/HelpLink'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import AvailabilityCheckActions, {
    AvailabilityCheckAction,
    AvailabilityCheckField,
} from 'store/AvailabilityCheck/AvailabilityCheck.actions'
import BankDetailsActions, { BankDetailsAction } from 'store/BankDetails/BankDetails.actions'
import ContactDataActions, { ContactDataAction } from 'store/ContactData/ContactData.actions'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import PortabilityStateActions, { PortabilityStateAction } from 'store/PortabilityState/PortabilityState.actions'
import { AppState } from 'store/store'
import TestIDs from 'utils/TestIDs'

const HeaderComponent = (): JSX.Element => {
    const history = useHistory()
    const { customizeJsData } = useSelector((appState: AppState) => {
        return {
            customizeJsData: appState.generalState.customizeJsData,
        }
    })

    const dispatch =
        useDispatch<
            Dispatch<
                | AvailabilityCheckActions
                | BankDetailsActions
                | ContactDataActions
                | GeneralStateActions
                | PortabilityStateActions
            >
        >()
    const availabilityDispatch = useDispatch<Dispatch<AvailabilityCheckActions>>()

    const resetState = React.useCallback(() => {
        dispatch({ type: AvailabilityCheckAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: ContactDataAction.SET_TO_INIT_STATE })
        dispatch({ type: BankDetailsAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: GeneralStateAction.SET_TO_INIT_STATE, payload: null })
        dispatch({ type: PortabilityStateAction.SET_TO_INIT_STATE, payload: null })
    }, [dispatch])

    const setZip = React.useCallback(
        (zip: string) => {
            availabilityDispatch({ type: AvailabilityCheckAction.SET_ZIP, payload: zip })
        },
        [availabilityDispatch],
    )
    const setFocus = React.useCallback(
        (focusId: AvailabilityCheckField) => {
            availabilityDispatch({ type: AvailabilityCheckAction.SET_FOCUSED_FIELD, payload: focusId })
        },
        [availabilityDispatch],
    )

    const onClickReturn = (): void => {
        if (customizeJsData?.globalConfiguration.logoClickWebsite !== undefined)
            window.open(customizeJsData?.globalConfiguration.logoClickWebsite, '_self')
        else {
            setZip('')
            setFocus(AvailabilityCheckField.ZIP)
            resetState()
            history.push('/')
        }
    }

    return (
        <AppBar position={'static'} {...TestIDs.GET_HEADER_COMPONENT('')} elevation={0}>
            <Box paddingLeft={3} paddingRight={3}>
                <Grid container>
                    <Grid item xs={12} sm={6} {...TestIDs.GET_HEADER_COMPONENT('LOGO')} className="HeaderIconWrapper">
                        <Box width={1} textAlign={{ xs: 'center', sm: 'left' }}>
                            <IconButton
                                disableFocusRipple={true}
                                disableRipple={true}
                                disableTouchRipple={true}
                                onClick={onClickReturn}
                                {...TestIDs.GET_HEADER_COMPONENT('LOGOICON_BUTTON')}
                            >
                                <img
                                    className="HeaderIcon"
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/customize/' +
                                        customizeJsData?.globalConfiguration.icons.header
                                    }
                                    alt={'placeholder'}
                                    style={{ maxWidth: 210, width: '100%' }}
                                />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        sm={6}
                        {...TestIDs.GET_HEADER_COMPONENT('HELP_BUTTON')}
                        alignItems={'center'}
                    >
                        <Box width={1} textAlign={{ xs: 'center', sm: 'right' }} marginTop={{ xs: 2, sm: 0 }}>
                            <HelpLink />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </AppBar>
    )
}

export default HeaderComponent
