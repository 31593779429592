import { PersistState } from 'redux-persist'
import PortabilityStateActions, { PortabilityStateAction } from './PortabilityState.actions'

export enum PortabilityViewFields {
    ADDRESS = 'address',
    CONTRACT_HOLDER = 'contractHolder',
    PROVIDER = 'providerDropDown',
    TELEPHONE = 'phoneInput',
    TERMINATED_CONTRACT = 'terminatedContract',
}
export interface PortabilityTelephone {
    areaCode: string
    number: string
}
export interface PortabilityContractHolder {
    firstName: string
    lastName: string
}
export interface PortabilityAddress {
    street: string
    houseNumber: string
    zip: string
    city: string
}
export interface PortabilityState {
    address: PortabilityAddress
    contractHolderOptions: PortabilityContractHolder[]
    endOfContract: string
    phoneOptions: PortabilityTelephone[]
    providerOptions: string[]
    selectedProvider: string
    selectedRadios: string[]
    _persist: PersistState
}

export const initialPortabilityState: PortabilityState = {
    address: {
        street: '',
        houseNumber: '',
        zip: '',
        city: '',
    },
    contractHolderOptions: [{ firstName: '', lastName: '' }],
    endOfContract: '',
    phoneOptions: [{ areaCode: '', number: '' }],
    providerOptions: [],
    selectedProvider: '',
    selectedRadios: [
        PortabilityViewFields.ADDRESS + 'Yes',
        PortabilityViewFields.CONTRACT_HOLDER + 'Yes',
        PortabilityViewFields.TERMINATED_CONTRACT + 'Yes',
    ],
    _persist: { rehydrated: false, version: 1 } as PersistState,
}

function PortabilityStateReducer(
    state: PortabilityState = initialPortabilityState,
    action: PortabilityStateActions,
): PortabilityState {
    switch (action.type) {
        case PortabilityStateAction.SET_TO_INIT_STATE:
            return {
                address: {
                    street: '',
                    houseNumber: '',
                    zip: '',
                    city: '',
                },
                contractHolderOptions: [{ firstName: '', lastName: '' }],
                endOfContract: '',
                phoneOptions: [{ areaCode: '', number: '' }],
                providerOptions: [],
                selectedProvider: '',
                selectedRadios: [
                    PortabilityViewFields.ADDRESS + 'Yes',
                    PortabilityViewFields.CONTRACT_HOLDER + 'Yes',
                    PortabilityViewFields.TERMINATED_CONTRACT + 'Yes',
                ],
                _persist: { rehydrated: false, version: 1 } as PersistState,
            }
        case PortabilityStateAction.SET_ADDRESS:
            return {
                ...state,
                address: action.payload,
            }
        case PortabilityStateAction.SET_CONTRACT_HOLDER_OPTIONS:
            return {
                ...state,
                contractHolderOptions: action.payload,
            }
        case PortabilityStateAction.SET_END_OF_CONTRACT:
            return {
                ...state,
                endOfContract: action.payload,
            }
        case PortabilityStateAction.SET_PHONE_OPTIONS:
            return {
                ...state,
                phoneOptions: action.payload,
            }
        case PortabilityStateAction.SET_PROVIDER_OPTIONS:
            return {
                ...state,
                providerOptions: action.payload,
            }
        case PortabilityStateAction.SET_SELECTED_PROVIDER:
            return {
                ...state,
                selectedProvider: action.payload,
            }
        case PortabilityStateAction.SET_SELECTED_RADIO:
            return {
                ...state,
                selectedRadios: action.payload,
            }
        case PortabilityStateAction.SET_PORTABILITY_STATE:
            return action.payload
        default:
            return state
    }
}

export default PortabilityStateReducer
