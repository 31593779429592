import { createContext, useContext } from 'react'

export type URLParamsFlags = {
    B2B: boolean
    loadTestData: boolean
    vzf: string | undefined
    preselectedZipcode: string | undefined
}

export const URLParams = (): URLParamsFlags => {
    const search = window.location.search
    const searchParams = new URLSearchParams(search)
    if (search.trim().length > 0)
        return {
            B2B: searchParams.has('b2b'),
            loadTestData: searchParams.has('t'),
            vzf: searchParams.get('vzf') ?? undefined, // Returns the parameter if it exists, null if not
            preselectedZipcode: searchParams.get('zipcode') ?? undefined, // Returns the parameter if it exists, null if not
        }
    return {
        B2B: false,
        loadTestData: false,
        vzf: undefined,
        preselectedZipcode: undefined,
    }
}

export const URLParamsContext = createContext<URLParamsFlags>(URLParams())

const useURLParams = (): URLParamsFlags => {
    return useContext(URLParamsContext)
}

export default useURLParams
