import gql from 'graphql-tag'

export default gql`
    query ValidateManualVoucher($input: ValidateManualVoucherInput!) {
        validateManualVoucher(input: $input) {
            code
            id
            type
            name
            articleNumber
            infoText
            products {
                articleId
                name
                price
                type
            }
            actions {
                articleId
                name
                price
                type
            }
            value {
                currency
                isOnce
                month
                value
            }
        }
    }
`
