import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
    PortabilityContractHolder,
    PortabilityTelephone,
    PortabilityViewFields,
} from 'store/PortabilityState/PortabilityState.reducer'
import TestIDs from 'utils/TestIDs'
import AddRemoveButton from './AddRemoveButton'
import RenderTextFields from './RenderTextFields'

export interface RenderPhoneNumbersProps {
    addOption: (field: PortabilityViewFields) => void
    isOptionEmpty: (option: PortabilityTelephone | PortabilityContractHolder) => boolean
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    options: PortabilityTelephone[]
    removeOption: (field: PortabilityViewFields) => void
    telephoneObjectLength: number
    phoneNumbersError: (field: string, index: number) => boolean
}
const RenderPhoneNumbers = (props: RenderPhoneNumbersProps): JSX.Element => {
    // prettier-ignore
    const {
        addOption,
        isOptionEmpty,
        phoneNumbersError,
        onChange,
        options,
        removeOption,
        telephoneObjectLength,
    } = props
    const { t } = useTranslation()
    const field = PortabilityViewFields.TELEPHONE

    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
                <Grid item xs={12}>
                    <Typography {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_TITLE')} variant={'h4'}>
                        {t('portabilityDetailsStrings.' + field + '.Title')}
                    </Typography>
                    <Typography
                        {...TestIDs.GET_PORTABILITY_DETAILS('INPUT', field + '_SUBTITLE')}
                        variant={'subtitle1'}
                        className={'DesiredDateHelpText'}
                    >
                        {t('portabilityDetailsStrings.' + field + '.SubTitle')}
                    </Typography>
                </Grid>
                {options.map((option: PortabilityTelephone, index: number) => (
                    <RenderTextFields
                        key={index}
                        field={field}
                        firstLabel={t('portabilityDetailsStrings.' + field + '.FirstLabel')}
                        firstValue={option.areaCode}
                        index={index}
                        onChange={onChange}
                        secondLabel={t('portabilityDetailsStrings.' + field + '.SecondLabel')}
                        secondValue={option.number}
                        firstValueError={index > 0 ? phoneNumbersError('areaCode', index) : undefined}
                        secondValueError={index > 0 ? phoneNumbersError('number', index) : undefined}
                        phone={true}
                    />
                ))}
            </Grid>
            <AddRemoveButton
                addOption={addOption}
                field={field}
                isOptionEmpty={isOptionEmpty}
                objectLength={telephoneObjectLength}
                options={options}
                removeOption={removeOption}
            />
        </>
    )
}

export default RenderPhoneNumbers
