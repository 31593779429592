import { sumBy } from 'lodash'
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { Page, SelectedProductCategory, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'
import useURLParams from 'utils/URLParamsContex'
import { OptionItemContent } from '../../components/OptionItem/OptionItem'
import { BillMode, Discount, ProductCategory } from '../../graphql/types'
import { getProductFromData } from '../../utils/testable/getProductFromData'

interface ProductTypeSelectionReducerReturn {
    availableProductCategories: ProductCategory[]
    currentView: ViewType
    selectedProductCategoryFromData: ProductCategory | undefined
    setSelectedProductType: (payload: {
        productCategoryId: string
        productId: string
        productTypeId: string
        B2B: boolean
    }) => void
    productTypesToShow: OptionItemContent[]
    selectedProductCategory: SelectedProductCategory | undefined
    pagesList: Page[]
    currentPage: number
    disabledSubmit: boolean
    productType: string
}

interface ProductTypeSelectionParam {
    id: string
    productType: string
}

export const useProductTypeSelectionReducer: () => ProductTypeSelectionReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { B2B } = useURLParams()
    const { id, productType } = useParams<ProductTypeSelectionParam>()
    // const [selectedProductCategoryFromData, setSelectedProductCategoryFromData] = useState<ProductCategory>()
    const [selectedProductCategoryFromData] = useState<ProductCategory>()
    const [productTypesToShow, setProductTypesToShow] = useState<Array<OptionItemContent>>([])

    const history = useHistory()

    const {
        availableProductCategories,
        currentView,
        selectedProductCategories,
        currentPage,
        pagesList,
        customizeJsData,
        isOnBack,
    } = useSelector((appState: AppState) => {
        return {
            availableProductCategories: appState.generalState.availableProductCategories,
            currentView: appState.generalState.currentView,
            selectedProductCategories: appState.generalState.selectedProductCategories,
            pagesList: appState.generalState.pagesList,
            currentPage: appState.generalState.currentPage,
            customizeJsData: appState.generalState.customizeJsData,
            isOnBack: appState.generalState.isOnBack,
        }
    })

    const setSelectedProductType = useCallback(
        (payload: { productCategoryId: string; productId: string; productTypeId: string; B2B: boolean }) => {
            dispatch({ type: GeneralStateAction.SELECT_PRODUCT_TYPE, payload })
        },
        [dispatch],
    )

    const setOnBack = useCallback(
        (payload: boolean): void => {
            dispatch({ type: GeneralStateAction.SET_ON_BACK, payload })
        },
        [dispatch],
    )

    const disabledSubmit = useMemo(() => {
        const selectedProductCategory = selectedProductCategories.find(
            (value) => value.selectedProduct && value.selectedProduct.id === id,
        )
        if (selectedProductCategory) {
            return false
        }
        return true
    }, [selectedProductCategories, history.location.pathname])

    useEffect(() => {
        const selectedProductCategory = selectedProductCategories.find(
            (value) => value.selectedProduct && value.selectedProduct.id === id,
        )
        if (selectedProductCategory) {
            const productFromData = getProductFromData(availableProductCategories, id)
            if (productFromData) {
                const productTypeOptions: OptionItemContent[] = []
                for (const pt of productFromData.productTypes) {
                    if (pt.identifier !== productType) {
                        continue
                    }

                    const productPrice = B2B ? pt.priceNet : pt.gross
                    let description: string[] | undefined
                    let oldPrice: number | undefined
                    let newPrice: number = productPrice as number
                    if (pt.discounts.length > 0) {
                        description = pt.discounts.map((discount) => discount.description)
                        oldPrice = productPrice as number
                        newPrice =
                            oldPrice -
                            sumBy(pt.discounts, function (d: Discount) {
                                return B2B ? d.priceNet : d.gross
                            })
                    }

                    productTypeOptions.push({
                        id: pt.id,
                        title: pt.title,
                        price: {
                            billMode: pt.billMode ? pt.billMode : BillMode.IGNORE,
                            newPrice,
                            oldPrice,
                            description,
                            info: pt.information
                                ? {
                                      title: pt.information,
                                  }
                                : undefined,
                        },
                        selectedImage: undefined,
                        unselectedImage: undefined,
                        subtitle: pt.subtitle ?? '',
                    })
                }
                setProductTypesToShow(productTypeOptions)
                if (
                    productTypeOptions.length > 0 &&
                    customizeJsData &&
                    customizeJsData.globalConfiguration.useCustomCombiDefaultSelection
                ) {
                    if (
                        selectedProductCategory?.selectedProduct?.productTypes.findIndex(
                            (value) => value.id === productTypeOptions[0].id,
                        ) === -1 &&
                        !isOnBack
                    ) {
                        setSelectedProductType({
                            productCategoryId: selectedProductCategory?.id ?? '',
                            productId: selectedProductCategory?.selectedProduct?.id ?? '',
                            productTypeId: productTypeOptions[0].id,
                            B2B,
                        })
                    } else {
                        setOnBack(false)
                    }
                }
            }
        }
    }, [productType, id])

    return {
        availableProductCategories,
        currentView,
        selectedProductCategoryFromData,
        setSelectedProductType,
        productTypesToShow,
        productType,
        selectedProductCategory: selectedProductCategories.find((value) => value.selectedProduct?.id === id),
        currentPage,
        pagesList,
        disabledSubmit,
    }
}
