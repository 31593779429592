export interface EnvConfig {
    HTTPListen: string
    Domain: string
    SentryDSN: string
    SentryENV: string
    KeycloakURL: string
    KeycloakRealm: string
    KeycloakClientIDOP: string
}

export default function getEnvConfig(): EnvConfig {
    // @ts-expect-error: __envConfig came from backend
    const envConfig: EnvConfig = __envConfig
    return envConfig
}
