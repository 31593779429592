import { useLazyQuery } from '@apollo/client'
import {
    AutocompleteChangeDetails,
    AutocompleteChangeReason,
    AutocompleteCloseReason,
    AutocompleteInputChangeReason,
} from '@material-ui/lab'
import LoadAddresses from 'graphql/queries/LoadAddresses'
import LoadAddressesDetails from 'graphql/queries/LoadAddressesDetails'
import { PostCodeDetail, PostCodeObject, Query } from 'graphql/types'
import { useEffect, useState } from 'react'

export interface PostCodeAddrState {
    term: string
    onChangeTerm: (event: React.ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => void

    options: PostCodeObject[]
    onSelectOption: (
        event: React.ChangeEvent<unknown>,
        value: string | PostCodeObject,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<PostCodeObject> | undefined,
    ) => void

    open: boolean
    onOpen: (event: React.ChangeEvent<unknown>) => void
    onClose: (event: React.ChangeEvent<unknown>, reason: AutocompleteCloseReason) => void
}
const usePostCodeAddrState = (handleAddressSelect: (payload: PostCodeDetail) => void): PostCodeAddrState => {
    const [options, setOptions] = useState<PostCodeObject[]>([])
    const [term, setTerm] = useState('')
    const [context, setContext] = useState('deu')
    const [open, setOpen] = useState(false)

    const [loadAddresses] = useLazyQuery<Query>(LoadAddresses, {
        onCompleted: (data) => {
            if (data && data.loadAddresses) {
                setOptions(data.loadAddresses.matches)
            }
        },
    })

    const [loadAddressesDetail] = useLazyQuery<Query>(LoadAddressesDetails, {
        onCompleted: (data) => {
            if (data && data.loadAddressesDetail) {
                handleAddressSelect(data.loadAddressesDetail)
                setTerm('')
                setContext('deu')
                setOpen(false)
                setOptions([])
            }
        },
    })

    const onOpen = () => setOpen(true)

    const onClose = (_: React.ChangeEvent<unknown>, reason: AutocompleteCloseReason) => {
        if (reason !== 'select-option') setOpen(false)
    }

    const onChangeTerm = (_: React.ChangeEvent<unknown>, value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'input') setTerm(value)
    }

    const onSelectOption = (_: React.ChangeEvent<unknown>, value: string | PostCodeObject) => {
        const postCode = value as PostCodeObject
        if (postCode && postCode.precision === 'Address') {
            loadAddressesDetail({ variables: { context: postCode.context } })
        } else if (postCode && postCode.value) {
            setTerm(postCode.value)
            setContext(postCode.context)
        }
    }

    useEffect(() => {
        if (term !== '') {
            const timerId = setTimeout(() => {
                loadAddresses({ variables: { input: { context, term } } })
            }, 500)

            return () => {
                clearTimeout(timerId)
            }
        } else setOptions([])
    }, [term, context])

    return {
        onChangeTerm,
        onClose,
        onOpen,
        onSelectOption,
        open,
        options,
        term,
    }
}

export default usePostCodeAddrState
