// checks if input is a valid phone number
export const isPhoneNumber = (phoneNumber: string): boolean => {
    // regexp for this kind of number: +(123) - 456-78-90
    const phoneRegExp = /^[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
    return phoneRegExp.test(phoneNumber)
}

// replaces the input with all valid phone number characters
// valid chars: 0-9 () / -
export const validatePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/[^+()0-9-// ]/g, '')
}
