import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'

export enum AvailabilityCheckAction {
    DEFAULT = 'DEFAULT',
    SET_CITIES = 'SET_CITIES',
    SET_FOCUSED_FIELD = 'SET_FOCUSED_FIELD',
    SET_HOUSE_NUMBERS = 'SET_NUMBERS',
    SET_SELECT_CITY = 'SET_SELECT_CITY',
    SET_SELECT_HOUSE_NUMBER = 'SET_SELECT_NUMBER',
    SET_SELECT_STREET = 'SET_SELECT_STREET',
    SET_STREETS = 'SET_STREETS',
    SET_TO_INIT_STATE = 'SET_AVAILABILITY_TO_INIT_STATE',
    SET_ZIP = 'SET_ZIP',
    SET_AVAILABILITY_STATE = 'SET_AVAILABILITY_STATE',
    SET_VZF = 'SET_VZF',
    SET_VZF_NO_RESULT = 'SET_VZF_NO_RESULT',
}

export enum AvailabilityCheckField {
    NONE = -1,
    ZIP,
    CITY,
    STREET,
    HOUSE_NUMBER,
    SUBMIT,
}

type AvailabilityCheckActions =
    | { type: AvailabilityCheckAction.DEFAULT }
    | { payload: AvailabilityCheckField; type: AvailabilityCheckAction.SET_FOCUSED_FIELD }
    | { payload: null; type: AvailabilityCheckAction.SET_TO_INIT_STATE }
    | { payload: string; type: AvailabilityCheckAction.SET_SELECT_CITY }
    | { payload: string; type: AvailabilityCheckAction.SET_SELECT_HOUSE_NUMBER }
    | { payload: string; type: AvailabilityCheckAction.SET_SELECT_STREET }
    | { payload: string; type: AvailabilityCheckAction.SET_ZIP }
    | { payload: string[]; type: AvailabilityCheckAction.SET_CITIES }
    | { payload: string[]; type: AvailabilityCheckAction.SET_HOUSE_NUMBERS }
    | { payload: string[]; type: AvailabilityCheckAction.SET_STREETS }
    | { payload: AvailabilityCheckState; type: AvailabilityCheckAction.SET_AVAILABILITY_STATE }
    | { payload: string; type: AvailabilityCheckAction.SET_VZF }
    | { payload: boolean; type: AvailabilityCheckAction.SET_VZF_NO_RESULT }

export default AvailabilityCheckActions
