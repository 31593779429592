import { Box, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useURLParams from 'utils/URLParamsContex'

export interface TaxProps {
    style?: React.CSSProperties
    variant?: Variant
    displayFootNote: boolean
}

const TaxInfo = (props: TaxProps): JSX.Element => {
    const { B2B } = useURLParams()
    const { style, variant } = props
    const { t } = useTranslation()
    return (
        <Box style={style}>
            {B2B === true && <Typography variant={variant ? variant : 'body1'}>{t('tax-b2b')}</Typography>}
            {B2B === false && <Typography variant={variant ? variant : 'body1'}>{t('tax-b2c')}</Typography>}
        </Box>
    )
}

export default TaxInfo
