import EditViewWrapper from 'components/EditViewWrapper'
import { renderPortabilityView } from 'pages/PortabilityView/PortabilityView'
import { useTranslation } from 'react-i18next'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { useEditReducer } from './useEditReducer'

const EditPortability = (): JSX.Element => {
    const { t } = useTranslation()

    const {
        loadState,
        onBackPortability,
        onSubmit,
        errors,
        customizeJsData,
        disabledSubmit,
        b2b,
        editAddOption,
        editAddress,
        editContractHolderOptions,
        editEndOfContract,
        handleEditAddressSelected,
        handleEditInputProvider,
        handleEditSelectProvider,
        isOptionEmpty,
        missingFields,
        onChangePortability,
        phoneNumbersError,
        editPhoneOptions,
        editProviderOptions,
        editRemoveOption,
        editSelectedProvider,
        editSelectedRadios,
    } = useEditReducer()

    const data = {
        title: t('editPage.headerPortability'),
        content: renderPortabilityView({
            B2B: b2b,
            addOption: editAddOption,
            customizeJsData,
            address: editAddress,
            contractHolderOptions: editContractHolderOptions,
            endOfContract: editEndOfContract,
            handleAddressSelected: handleEditAddressSelected,
            handleInputProvider: handleEditInputProvider,
            handleSelectProvider: handleEditSelectProvider,
            isOptionEmpty,
            missingFields,
            onChange: onChangePortability,
            phoneNumbersError,
            phoneOptions: editPhoneOptions,
            providerOptions: editProviderOptions,
            removeOption: editRemoveOption,
            selectedProvider: editSelectedProvider,
            selectedRadios: editSelectedRadios,
        }),
        type: ViewType.PORTABILITY_DETAILS,
    }
    return (
        <EditViewWrapper
            header={data.title}
            viewType={data.type}
            errorCategory={data.type}
            disabledSubmit={disabledSubmit}
            loadState={loadState}
            errors={errors}
            onBack={onBackPortability}
            onSubmit={onSubmit}
        >
            {data.content}
        </EditViewWrapper>
    )
}

export default EditPortability
