import { Fade } from '@material-ui/core'
import * as React from 'react'

export interface OptionImageProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    selectedImage: string
    unselectedImage: string
    selected: boolean
}

const OptionImage = (props: OptionImageProps): JSX.Element => {
    const { selectedImage, unselectedImage, selected, ...rest } = props
    return (
        <>
            <Fade in={selected} exit={false} unmountOnExit={true} timeout={250} disableStrictModeCompat={true}>
                <img src={process.env.PUBLIC_URL + '/customize/' + selectedImage} alt={selectedImage} {...rest} />
            </Fade>
            <Fade in={!selected} exit={false} unmountOnExit={true} timeout={250} disableStrictModeCompat={true}>
                <img src={process.env.PUBLIC_URL + '/customize/' + unselectedImage} alt={unselectedImage} {...rest} />
            </Fade>
        </>
    )
}

export default OptionImage
