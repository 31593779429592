import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@material-ui/core'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { getTestIDOnViewType } from 'utils/TestIDs'
import { ViewType } from '../../store/GeneralState/GeneralState.reducer'

export interface DropdownProps extends SelectProps {
    label: string
    options: string[]
    testIdentifier: {
        viewType: ViewType
        input: string
        category?: string
    }
}

const Dropdown = (props: DropdownProps): JSX.Element => {
    const { t } = useTranslation()
    const { className, disabled, inputRef, label, onChange, options, testIdentifier, value } = props
    const iconComponent = (e: HTMLAttributes<HTMLImageElement>): JSX.Element => (
        <img
            className={e.className}
            style={{ marginRight: 16, marginTop: 8 }}
            alt={'button chevron'}
            src={process.env.PUBLIC_URL + '/customize/DropdownIcon.svg'}
        />
    )

    return (
        <FormControl variant={'filled'} fullWidth={true}>
            <InputLabel id={label}>{label}</InputLabel>
            <Select
                {...getTestIDOnViewType(testIdentifier.viewType, testIdentifier.input, testIdentifier.category)}
                disabled={disabled}
                inputRef={inputRef}
                labelId={label}
                variant={'outlined'}
                fullWidth={true}
                IconComponent={iconComponent}
                inputProps={{
                    className: label.length === 0 ? 'noLabel' : '',
                    MenuProps: { disableScrollLock: true },
                }}
                onChange={onChange}
                value={value}
                className={className}
                displayEmpty={value === '' ? true : undefined}
                MenuProps={{
                    PopoverClasses: { paper: 'DropdownPaper' },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem disabled={option === ''} key={option + index} value={option}>
                        {option === '' ? t('pleaseChoose') : t(option)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default Dropdown
