import { OrderDataInput } from 'graphql/types'
import { AppState } from 'store/store'

export type EncryptionResponse = {
    data: string
    key: string
}

const aesIV = new Uint8Array([
    0xf4, 0x3d, 0xca, 0xd4, 0x85, 0xb4, 0x95, 0xcb, 0x9a, 0x76, 0xfd, 0x16, 0xd3, 0x53, 0x83, 0xb5,
])

const stringToHex = (str: string): string => {
    const hexArray = []
    for (let n = 0, l = str.length; n < l; n++) {
        const hex = Number(str.charCodeAt(n)).toString(16)
        hexArray.push(hex)
    }
    return hexArray.join('')
}

const hexToString = (hex: string): string => {
    let str = ''
    for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16))
    }
    return str
}

export const generateKeyAndExportIt = async (): Promise<[CryptoKey, string]> => {
    const aesKey = await crypto.subtle.generateKey({ name: 'AES-CBC', length: 256 }, true, ['encrypt', 'decrypt'])
    const exportedKey = await crypto.subtle.exportKey('raw', aesKey)
    const hex = stringToHex(new Uint8Array(exportedKey).toString())
    return [aesKey, hex]
}

export const encryption = async (data: OrderDataInput | AppState, key: CryptoKey): Promise<string> => {
    const cbc = { name: 'AES-CBC' }
    const encoded = new TextEncoder().encode(JSON.stringify(data))
    const encrypted = await crypto.subtle.encrypt({ ...cbc, iv: aesIV }, key, encoded)
    return stringToHex(new Uint8Array(encrypted).toString())
}

export const decryption = async (hexEncryptedData: string, hexKey: string): Promise<OrderDataInput> => {
    const dataStringArray = hexToString(hexEncryptedData).split(',')
    const dataNumberArray: number[] = []
    dataStringArray.forEach((value) => dataNumberArray.push(Number(value)))
    const dataUint8Array = new Uint8Array(dataNumberArray)
    const keyStringArray = hexToString(hexKey).split(',')
    const keyNumberArray: number[] = []
    keyStringArray.forEach((value) => keyNumberArray.push(Number(value)))
    const keyUint8Array = new Uint8Array(keyNumberArray)
    const cbc = { name: 'AES-CBC' }
    const importedKey = await crypto.subtle.importKey('raw', keyUint8Array, cbc, true, ['encrypt', 'decrypt'])
    const decrypted = await crypto.subtle.decrypt({ ...cbc, iv: aesIV }, importedKey, dataUint8Array)
    const decoded = new TextDecoder().decode(decrypted)
    return JSON.parse(decoded)
}
