import { Error } from 'store/GeneralState/GeneralState.reducer'

export const checkIfErrorCategoryExists = (errors: Error[], category: string): boolean =>
    errors.filter((error) => error.category === category).length > 0

export const checkIfErrorExistsInsideErrorCategory = (
    errors: Error[],
    category: string,
    errorToCheck: string,
): boolean =>
    errors.filter(
        (errorCategory) =>
            errorCategory.category === category &&
            errorCategory.errors.filter((error) => error === errorToCheck).length > 0,
    ).length > 0
