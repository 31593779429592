import { Product, ProductCategory } from '../../graphql/types'

export const getProductFromData = (
    availableProductCategories: ProductCategory[],
    productID: string,
): Product | undefined => {
    let productToReturn: Product | undefined = undefined

    availableProductCategories.forEach((productCategory) => {
        productCategory.products.forEach((product) => {
            if (product.id === productID) {
                productToReturn = product
            }
        })
    })

    return productToReturn
}
