export interface EllipseIconProps {
    color: string
    width: string
    height: string
}

const EllipseIcon = (props: EllipseIconProps): JSX.Element => {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox={'0 0 19 19'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
        >
            <circle cx={'9.5'} cy={'9.5'} r={'9.5'} fill={props.color} />
        </svg>
    )
}

export default EllipseIcon
