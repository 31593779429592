import gql from 'graphql-tag'

export default gql`
    query getLoggedUser($id: String!) {
        loadLoggedUser(id: $id) {
            email
            customerNumber
            name
            roles
        }
    }
`
