import { TextField } from '@material-ui/core'
import IBAN from 'iban'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import TestIDs from 'utils/TestIDs'

interface IbanProps {
    onChange: (iban: string) => void
    value: string
    label: string
    ibanError: boolean
}

const IbanInput = (props: IbanProps): JSX.Element => {
    const { label, onChange, value, ibanError } = props
    const { t } = useTranslation()

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(event.target.value)
    }

    const handleOnBlur = (): void => {
        onChange(IBAN.printFormat(value))
    }

    return (
        <TextField
            {...TestIDs.GET_BANK_DETAILS('IBAN_INPUT')}
            type={'text'}
            fullWidth={true}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={value}
            label={label}
            InputProps={{ className: ibanError ? 'TextError' : '' }}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
            error={ibanError}
            helperText={ibanError && t('error.bankDetails.insertValidIBAN')}
        />
    )
}

export default IbanInput
