import * as DateFns from 'date-fns'
import { addBusinessDays } from 'date-fns'
// import ProductCategoriesQuery from 'graphql/queries/ProductCategories'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import { AppState } from 'store/store'

interface ProductSelectionReducerReturn {
    currentView: ViewType
    desiredDate: Date | null
    earliestDatePossible: boolean
    minDate: () => Date
    maxDate: () => Date | number
    formatDateString: (date: Date) => string
    onDateChange: (date: Date | null) => void
    setDesiredDate: (payload: Date | null) => void
    setEarliestDatePossible: (payload: boolean) => void
    shouldDisableDate: (day: Date | null) => boolean
}

export const useDesiredDateReducer: () => ProductSelectionReducerReturn = () => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { currentView, customizeJsData, desiredDate, earliestDatePossible, startOfDelivery } = useSelector(
        (appState: AppState) => {
            return {
                currentView: appState.generalState.currentView,
                customizeJsData: appState.generalState.customizeJsData,
                desiredDate: appState.generalState.desiredDate,
                earliestDatePossible: appState.generalState.earliestDatePossible,
                startOfDelivery: appState.generalState.startOfDelivery,
            }
        },
    )

    const setDesiredDate = useCallback(
        (payload: Date | null) => {
            dispatch({ type: GeneralStateAction.SET_DESIRED_DATE, payload })
        },
        [dispatch],
    )

    const setEarliestDatePossible = useCallback(
        (payload: boolean) => {
            dispatch({ type: GeneralStateAction.SET_EARLIEST_DATE_POSSIBLE, payload })
        },
        [dispatch],
    )

    const minDate = (): Date => {
        const dummyToday: Date = new Date()
        if (customizeJsData) {
            const today = addBusinessDays(new Date(), customizeJsData.installationConfiguration.minInstallationDate)
            if (startOfDelivery) {
                const deliveryDate = new Date(startOfDelivery)
                if (today.getTime() < deliveryDate.getTime()) {
                    return deliveryDate
                }
            }
            return today
        } else {
            return dummyToday
        }
    }
    const maxDate = (): Date | number => {
        if (customizeJsData) {
            const today = new Date()
            if (customizeJsData.installationConfiguration.maxInstallationDate === 0) {
                return 0
            }
            if (startOfDelivery) {
                const deliveryDate = new Date(startOfDelivery)
                if (today.getTime() < deliveryDate.getTime()) {
                    return addBusinessDays(deliveryDate, customizeJsData.installationConfiguration.maxInstallationDate)
                }
            }
            return addBusinessDays(today, customizeJsData.installationConfiguration.maxInstallationDate)
        } else {
            return 0
        }
    }
    const formatDateString = (date: Date): string => DateFns.format(new Date(date), 'dd.MM.yyyy').toString()
    const onDateChange = (date: Date | null): void => {
        setDesiredDate(date)
    }

    const shouldDisableDate = (day: Date | null): boolean => {
        if (day && DateFns.isWeekend(day)) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (earliestDatePossible) {
            setDesiredDate(minDate())
        }
    }, [earliestDatePossible])

    return {
        currentView,
        desiredDate,
        formatDateString,
        maxDate,
        minDate,
        onDateChange,
        earliestDatePossible,
        setDesiredDate,
        setEarliestDatePossible,
        shouldDisableDate,
    }
}
