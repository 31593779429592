import { Box, Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ProductTableRow, { ProductTableRowProps } from '../ProductTableRow/ProductTableRow'

export interface ProductTableCategoryProps {
    title: string
    products: ProductTableRowProps[]
    iconStyle?: React.CSSProperties
    isBasket?: boolean
}

const ProductTableCategory = (props: ProductTableCategoryProps): JSX.Element => {
    const { t } = useTranslation()
    const { iconStyle, title, products, isBasket } = props
    return (
        <Grid container className={'ProductTableCategory'}>
            <Grid item xs={12}>
                <Box paddingTop={2} paddingBottom={2}>
                    <Typography variant={'h5'} className={'ProductTableHeadline'}>
                        {t(title)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {products.map((product: ProductTableRowProps, index: number) => (
                    <ProductTableRow key={index} iconStyle={iconStyle} {...product} isBasket={isBasket} />
                ))}
            </Grid>
        </Grid>
    )
}

export default ProductTableCategory
