import { Box, Fade, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from '../../utils/colors'
import CheckIcon from './CheckIcon'
import EllipseIcon from './EllipseIcon'

export enum ProgressListItemState {
    UNFINISHED = 'unfinished',
    ACTIVE = 'active',
    FINISHED = 'finished',
}

export interface ProgressListSubItemtype {
    i18nKey: string
    status: ProgressListItemState
    index?: number
}

export interface ProgressListSubItemProps {
    progressListSubItem: ProgressListSubItemtype
    isSubItem: boolean
    finishedAll?: boolean
}

const ProgressListSubItem = (props: ProgressListSubItemProps): JSX.Element => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { progressListSubItem, isSubItem, finishedAll } = props
    const { i18nKey, status } = progressListSubItem

    return (
        <ListItem className={'progressList'}>
            <ListItemText className={'progressList'}>
                <Typography
                    variant={'body2'}
                    className={isSubItem ? 'progressListSubItem' : 'progressListItem'}
                    style={{
                        color:
                            status === ProgressListItemState.UNFINISHED
                                ? isSubItem
                                    ? theme.palette.text.disabled
                                    : theme.palette.text.disabled
                                : status === ProgressListItemState.ACTIVE
                                ? isSubItem
                                    ? theme.palette.text.primary
                                    : theme.palette.text.hint
                                : status === ProgressListItemState.FINISHED
                                ? isSubItem
                                    ? finishedAll
                                        ? theme.palette.text.secondary
                                        : theme.palette.text.primary
                                    : !finishedAll
                                    ? theme.palette.text.secondary
                                    : finishedAll
                                    ? theme.palette.text.secondary
                                    : theme.palette.text.hint
                                : theme.palette.text.secondary,
                    }}
                >
                    {t(i18nKey)}
                </Typography>
            </ListItemText>
            <Box style={isSubItem ? { paddingRight: 3 } : undefined}>
                {!isSubItem && (
                    <div>
                        <Fade
                            in={status === ProgressListItemState.FINISHED}
                            exit={false}
                            unmountOnExit={true}
                            timeout={250}
                            disableStrictModeCompat={true}
                        >
                            <Box paddingTop={0.5}>
                                <CheckIcon color={colors.white} width={'20px'} height={'14px'} />
                            </Box>
                        </Fade>
                        <Fade
                            in={status !== ProgressListItemState.FINISHED}
                            exit={false}
                            unmountOnExit={true}
                            timeout={250}
                            disableStrictModeCompat={true}
                        >
                            <Box paddingTop={0.5}>
                                <EllipseIcon
                                    color={
                                        status === ProgressListItemState.UNFINISHED ? colors.lightGray : colors.green
                                    }
                                    width={'19px'}
                                    height={'19px'}
                                />
                            </Box>
                        </Fade>
                    </div>
                )}
                {isSubItem && (
                    <div>
                        <Fade
                            in={status === ProgressListItemState.FINISHED}
                            exit={false}
                            unmountOnExit={true}
                            timeout={250}
                            disableStrictModeCompat={true}
                        >
                            <CheckIcon
                                color={finishedAll ? colors.white : colors.color1}
                                width={'11px'}
                                height={'8px'}
                            />
                        </Fade>
                        <Fade
                            in={status !== ProgressListItemState.FINISHED}
                            exit={false}
                            unmountOnExit={true}
                            timeout={250}
                            disableStrictModeCompat={true}
                        >
                            <Box paddingRight={0.25}>
                                <EllipseIcon
                                    color={status === ProgressListItemState.ACTIVE ? colors.color1 : 'transparent'}
                                    width={'9px'}
                                    height={'9px'}
                                />
                            </Box>
                        </Fade>
                    </div>
                )}
            </Box>
        </ListItem>
    )
}

export default ProgressListSubItem
